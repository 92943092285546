<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :disabled="isArchived || disabled"
        small
        class="load-status-edit-button font-weight-bold"
        :class="{
          'yellow darken-2': buttonColors.plan || buttonColors.needsAppt,
          'green darken-2': buttonColors.available,
          'indigo lighten-1': buttonColors.booked,
          'purple lighten-1': buttonColors.confirmed,
          'blue lighten-1':
            buttonColors.picked ||
            buttonColors.pickOnTrack ||
            buttonColors.loading,
          'green lighten-1':
            buttonColors.delivered ||
            buttonColors.deliveryOnTrack ||
            buttonColors.unloading ||
            buttonColors.readyToBill,
          'red darken-1':
            buttonColors.claim ||
            buttonColors.deliveryOffTrack ||
            buttonColors.pickOffTrack,
          'blue-grey darken-2': buttonColors.invoiced,
          black: buttonColors.paid,
        }"
        v-on="on"
      >
        {{ !isArchived ? loadStatusDisplay : "Archived" }}
      </v-btn>
    </template>
    <v-list height="300">
      <v-list-item-group v-model="loadDisplay" color="accent">
        <v-list-item
          v-for="(item, index) in loadStatusItems"
          :key="index"
          @click="saveLoadUpdate(item.id)"
        >
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import common from "@/utils/common"
export default {
  name: "LoadStatusEditButton",
  props: {
    shipmentId: {
      type: Number,
      default: 0,
    },
    loadStatus: {
      type: [String, Number],
      default: "",
    },
    parentObject: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isArchived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadStatusDisplay: null,
      buttonColors: {
        plan: false,
        available: false,
        booked: false,
        confirmed: false,
        picked: false,
        delivered: false,
        claim: false,
        invoiced: false,
        paid: false,
        pickOnTrack: false,
        pickOffTrack: false,
        deliveryOnTrack: false,
        deliveryOffTrack: false,
        readyToBill: false,
        needsAppt: false,
        loading: false,
        unloading: false,
      },
      loadStatusItems: [],
      loadDisplay: null,
    }
  },
  watch: {
    loadStatus() {
      this.loadDisplay = this.setCurrentLoadStatus(this.loadStatus)
    },
  },
  mounted() {
    this.loadStatusItems = common.methods.getWithExpiry("load_status_choices")
    this.loadDisplay = this.setCurrentLoadStatus(this.loadStatus)
  },
  methods: {
    setCurrentLoadStatus(statusValue) {
      const loadStatusItems = common.methods.getWithExpiry(
        "load_status_choices"
      )
      if (statusValue !== null) {
        if (this.isNumeric(statusValue)) {
          const status = loadStatusItems.findIndex((x) => x.id === statusValue)
          this.determineButtonColor(loadStatusItems[status].name)
          return status
        } else {
          const status = loadStatusItems.findIndex(
            (x) => x.name === statusValue
          )
          this.determineButtonColor(statusValue.toLowerCase())
          return status
        }
      } else {
        return { id: 0, name: "" }
      }
    },
    saveLoadUpdate(value) {
      const loadStatusItems = common.methods.getWithExpiry(
        "load_status_choices"
      )
      const status = loadStatusItems.findIndex((x) => x.id === value)
      this.determineButtonColor(loadStatusItems[status].name)
      this.setCurrentLoadStatus(loadStatusItems[status].name)
      const payload = {
        loadId: this.shipmentId,
        loadStatus: value,
        loadStatusDisplay: loadStatusItems[status].name,
      }
      this.$emit("change", { loadStatus: payload })
    },
    determineButtonColor(statusName) {
      this.loadStatusDisplay = statusName
      for (const [key] of Object.entries(this.buttonColors)) {
        if (key.toLowerCase() !== statusName.toLowerCase().replace(" ", "")) {
          this.buttonColors[key] = false
        } else {
          this.buttonColors[key] = true
        }
      }
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n)
    },
  },
}
</script>

<style>
.v-application .v-item-group {
  background-color: white !important;
}
</style>
