<template>
  <v-select
    v-model="defaultContact"
    :items="data"
    item-text="name"
    item-value="id"
    :loading="loading"
    :rules="rules"
    class="mt-n2"
    color="#e1b753"
    :label="label"
    clearable
    @click:clear="fireClearEvent"
    @change="fireEnterEvent"
  >
    <template v-slot:item="{ item }">
      <div class="py-2">
        <div class="text-uppercase font-weight-medium">
          {{ item.name }}
        </div>
        <div>
          <span class="grey--text text--darken-1">Phone:</span>
          {{ item.phone || "—" }}
        </div>
        <div>
          <span class="grey--text text--darken-1">Email:</span>
          {{ item.email || "—" }}
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "CarrierContactSelect",
  props: {
    defaultContact: {
      type: Object,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      isEnterEvent: false,
    }
  },
  methods: {
    fireEnterEvent(typeId) {
      this.$emit("event", typeId)
    },
    fireClearEvent() {
      this.$emit("event", null)
    },
  },
}
</script>
