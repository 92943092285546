<template>
  <div>
    <v-row>
      <v-col class="pt-3 pb-0 pr-0" cols="12" sm="7">
        <v-list-item-subtitle
          class="blue--text text--lighten-2 pa-1 pl-3 white--text box-header"
        >
          Shipper info
          <v-btn color="grey" small text @click="saveShipperUpdate()">
            Save
          </v-btn>
        </v-list-item-subtitle>
      </v-col>
    </v-row>
    <div class="px-4 pt-6" style="height: 162px; overflow-y: auto">
      <v-form
        ref="shipperInfoEdit-form"
        v-model="shipperInfoFormValid"
        lazy-validation
      >
        <v-row>
          <v-col class="pr-3 pt-1 pb-0" cols="12">
            <location-auto-complete
              :customer-id="shipmentDetail.customer.id"
              :model="shipperInfo"
              direction="source"
              form-type="2"
              stop-sequence="1"
            />
          </v-col>
        </v-row>
        <v-row class="pt-0 pb-0">
          <v-col class="pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
            <v-text-field
              v-if="shipperInfo.id"
              v-model="shipperInfo.contactPhone"
              :rules="[rules.phone]"
              class="pt-0"
              label="Contact Phone"
              @keydown.enter.native="saveShipperUpdate()"
            />
            <v-text-field v-else class="pt-0" disabled label="Contact Phone" />
          </v-col>
          <v-col class="pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
            <v-text-field
              v-if="loadInfo"
              v-model="loadInfo.shipperEarlyTime"
              v-mask="'##:##'"
              :rules="[rules.time]"
              class="pt-0"
              label="Pickup Early"
              @keydown.enter.native="saveShipperUpdate()"
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
            <v-text-field
              v-if="loadInfo"
              v-model="loadInfo.shipperLateTime"
              v-mask="'##:##'"
              :rules="[rules.time]"
              class="pt-0"
              label="Pickup Late"
              @keydown.enter.native="saveShipperUpdate()"
            />
          </v-col>

          <v-col class="pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
            <v-text-field
              v-if="shipperInfo.id"
              v-model="shipperInfo.hours"
              class="pt-0"
              label="Location Hours"
              @keydown.enter.native="saveShipperUpdate()"
            />
            <v-text-field v-else class="pt-0" disabled label="Location Hours" />
          </v-col>
          <v-col class="pt-0 pb-0" cols="12" lg="4" md="6" sm="12">
            <v-text-field
              v-model="loadInfo.shipperPickupNumber"
              :rules="[rules.alphaNumeric]"
              class="pt-0"
              label="Pickup Number"
              @keydown.enter.native="saveShipperUpdate()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <v-textarea
              v-model="loadInfo.shipperNotes"
              auto-grow
              class="pt-0 pb-0"
              label="Notes"
              row-height="30"
              rows="4"
              @keydown.enter.native="saveShipperUpdate()"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import LocationAutoComplete from "@/views/pages/components/LocationAutoComplete"
import validation from "@/utils/form-validation"

export default {
  name: "ShipperInfoEdit",
  components: {
    LocationAutoComplete,
  },
  data() {
    return {
      valid: false,
      shipper: null,
      pickupEarlyMenu: false,
      pickupLateMenu: false,
      shipperInfoFormValid: false,
      rules: validation.rules,
    }
  },
  computed: {
    ...mapGetters(
      "loadDetail",
      {
        shipmentDetail: "shipmentDetail",
        shipperInfo: "shipperInfo",
        loadInfo: "loadInfo",
      },
      "location",
      {
        currentLocation: "currentLocation",
      }
    ),
  },
  mounted() {
    this.shipper = this.shipperInfo
  },
  methods: {
    saveShipperUpdate() {
      if (this.$refs["shipperInfoEdit-form"].validate()) {
        let payload = {}
        if (this.shipperInfo.id) {
          payload = {
            id: this.shipmentDetail.id,
            shipper: {
              id: this.shipperInfo.id,
              contact_phone: this.shipperInfo.contactPhone,
              city: this.shipperInfo.city,
              hours: this.shipperInfo.hours,
            },
            pickup_early_time: this.loadInfo.shipperEarlyTime,
            pickup_late_time: this.loadInfo.shipperLateTime,
            shipper_pickup_number: this.loadInfo.shipperPickupNumber,
            shipper_pickup_notes: this.loadInfo.shipperNotes,
          }
        } else {
          payload = {
            id: this.shipmentDetail.id,
            shipper: null,
            pickup_early_time: this.loadInfo.shipperEarlyTime,
            pickup_late_time: this.loadInfo.shipperLateTime,
            shipper_pickup_number: this.loadInfo.shipperPickupNumber,
            shipper_pickup_notes: this.loadInfo.shipperNotes,
          }
        }
        this.$store
          .dispatch("loadDetail/PATCH_LOAD_DETAIL", payload)
          .then(() => {
            this.$store.commit("loadDetail/setShipperDetailEditing", false)
            if (this.shipper.id !== payload.shipper.id) {
              this.$store.commit("loadDetail/setReloadMap", true)
            }
          })
          .catch((error) => {
            const payload = {
              status: true,
              text: "Could not update Shipper.",
              error: error,
            }
            this.$store.commit("main/SET_SNACKBAR", payload)
            this.$store.commit("loadDetail/setShipperDetailEditing", true)
          })
      }
    },
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}
</style>
