<template>
  <div v-if="!consigneeDetailEdit" class="pt-3" data-cy="consignee-info">
    <v-row>
      <v-col
        :sm="$vuetify.breakpoint.lgAndUp ? '7' : '9'"
        class="pt-0 pb-0 pr-0"
        cols="12"
      >
        <v-list-item-subtitle
          class="
            blue--text
            text--lighten-2
            pa-1
            pl-3
            pb-4
            white--text
            box-header
          "
        >
          Consignee info
          <v-btn
            v-if="!loadEditingDisabled"
            color="grey"
            small
            text
            @click="consigneeInfoEditing()"
          >
            Edit
          </v-btn>
        </v-list-item-subtitle>
      </v-col>
    </v-row>
    <div
      :style="
        $vuetify.breakpoint.mdAndUp && showEdit
          ? 'height: 150px; overflow-y: auto;'
          : ''
      "
    >
      <v-container class="pt-0">
        <v-card-text v-if="consignee.name" class="text--regular pa-0 py-0">
          <router-link
            :to="{
              name: 'Location Detail',
              params: { id: consignee.id },
            }"
          >
            {{ consignee.name }}
          </router-link>
        </v-card-text>
        <v-card-text v-else class="text--regular pa-0 pt-2">
          Assign Consignee
        </v-card-text>
        <v-list-item-subtitle class="grey--text text--darken-3 py-2">
          <span v-if="consignee.address">{{ consignee.address }}</span>
          <span v-if="consignee.city"> {{ consignee.city }}</span>
          <span v-if="consignee.stateProvinceRegion"
            >, {{ consignee.stateProvinceRegion }}</span
          >
          <span v-if="consignee.zipcode"> {{ consignee.zipcode }}</span>
        </v-list-item-subtitle>
        <v-row>
          <v-col cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Contact Phone</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="consignee.contactPhone" />
            </v-list-item-subtitle>
          </v-col>
          <v-col cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Delivery Early</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadInfo.consigneeEarlyTime" />
            </v-list-item-subtitle>
          </v-col>
          <v-col cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Delivery Late</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadInfo.consigneeLateTime" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Location Hours</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="consignee.hours" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Delivery Number</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadInfo.deliveryNumber" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="xs pt-0 pb-0" cols="12">
            <p class="mb-1 grey--text text--darken-1">Notes</p>
            <v-list-item-subtitle
              v-if="loadInfo.consigneeNotes"
              class="grey--text text--darken-4"
            >
              <!--<tooltip :tooltip-content="loadInfo.consigneeNotes" />-->
              <template
                v-if="
                  $vuetify.breakpoint.lgAndUp
                    ? loadInfo.consigneeNotes.length < 15
                    : loadInfo.consigneeNotes.length < 5
                "
              >
                {{ loadInfo.consigneeNotes }}
              </template>
              <template v-else>
                <v-dialog v-model="dialog" max-width="300px" scrollable>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="black"
                      dark
                      plain
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ loadInfo.consigneeNotes.substring(0, 40) }}...
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title> Consignee Notes</v-card-title>
                    <v-divider />
                    <v-card-text
                      style="height: 300px"
                      v-html="
                        loadInfo.consigneeNotes.replace(
                          /(?:\r\n|\r|\n)/g,
                          '<br>'
                        )
                      "
                    />
                  </v-card>
                </v-dialog>
              </template>
            </v-list-item-subtitle>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
  <consignee-info-edit v-else />
</template>
<script>
import { mapGetters } from "vuex"
import ConsigneeInfoEdit from "@/views/pages/load-detail-components/edit-components/ConsigneeInfoEdit"
import Tooltip from "@/views/pages/components/Tooltip"
import common from "@/utils/common"

export default {
  name: "ConsigneeInfo",
  components: {
    Tooltip,
    ConsigneeInfoEdit,
  },
  props: {
    hgtClass: {
      type: String,
      default: "hgt150",
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    menu: false,
    dialog: false,
  }),
  computed: {
    ...mapGetters("loadDetail", {
      consigneeInfo: "consigneeInfo",
      loadInfo: "loadInfo",
      consigneeDetailEdit: "consigneeDetailEdit",
      shipmentDetail: "shipmentDetail",
    }),
    ...mapGetters("generic", {
      agentDetail: "agentDetail",
    }),
    consignee() {
      return this.$store.state.loadDetail.consigneeInfo
    },
    loadEditingDisabled() {
      return common.methods.disableButtonBaseOnLoadStatus(
        this.shipmentDetail,
        this.agentDetail
      )
    },
  },
  methods: {
    consigneeInfoEditing() {
      this.$store.commit("loadDetail/setConsigneeDetailEditing", true)
    },
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}

.v-application a {
  color: #000;
  text-decoration: none;
}

.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
  padding: 0px !important;
  font-weight: bold;
  height: auto;
  text-transform: none;
}
</style>
