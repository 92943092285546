<template style="display: inline-block">
  <v-tooltip
    v-if="tooltipContent"
    :disabled="
      $vuetify.breakpoint.lgAndUp
        ? tooltipContent.length < 15
        : tooltipContent.length < 5
    "
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="text-truncate truncate"
        :style="type === 'text' && { marginLeft: '0' }"
        v-on="on"
      >
        {{ tooltipContent }}
      </div>
    </template>
    <div class="box">
      {{ tooltipContent }}
    </div>
  </v-tooltip>
</template>
<script>
export default {
  name: "Tooltip",
  props: {
    tooltipContent: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
  },
}
</script>

<style scoped>
.truncate {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 16px;
}
.box {
  max-width: 200px;
  overflow-wrap: break-word;
}
</style>
