<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="blue font-weight-bold"
        small
        v-bind="attrs"
        v-on="on"
        :loading="getPerformingLoadboardAction(loadId)"
        :disabled="isLoadboardsDisabled"
      >
        Loadboards
        <v-icon
          right
          dark
          v-if="hasFailedStatus"
        >
          mdi-alert-decagram
        </v-icon>
        <v-icon
          right
          dark
          v-if="allSuccessStatus"
        >
          mdi-checkbox-marked-circle
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title style="background-color: #1e88e5">
        <v-row class="align-center">
          <v-col cols="12">
            <span class="text-h2 white--text">Manage Loadboards</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pb-0 mb-2">
        <span class="loadboard-list">
          <span class="mb-1">
            <span> ALL </span>
            <v-simple-checkbox
              dense
              :ripple="false"
              @input="toggleAll"
              :value="allSelected"
            />
            <span />
          </span>
          <v-divider class="mb-1" />
          <span v-for="loadboard in loadboardState" :key="loadboard.name">
            <span><b>{{loadboard.name}}</b></span>
              <v-tooltip bottom :disabled="loadboard.isEnabled">
                <template v-slot:activator="{ on, attrs }">
                  <v-simple-checkbox
                    v-bind="attrs"
                    v-on="on"
                    dense
                    v-model="selected[loadboard.name]"
                    :ripple="false"
                    :disabled="!loadboard.isEnabled"
                  />
                </template>
                <span>Loadboard has been disabled</span>
              </v-tooltip>
            <span :class="getStatusClass(loadboard.status || '')">
              <v-tooltip bottom :disabled="!loadboard.latestError">
                <template v-slot:activator="{ on, attrs }">
                  <b
                    v-bind="attrs"
                    v-on="on"
                  >{{(loadboard.status || "NOT_POSTED").replace(/_/g, " ")}}</b>
                </template>
                <span>{{ loadboard.latestError && loadboard.latestError.message }}</span>
              </v-tooltip>
            </span>
          </span>
        </span>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn text @click="menu = false"> Close </v-btn>
        <v-btn
          color="red"
          text
          :disabled="isDeleteDisabled"
          @click="submit('DELETE')"
        > Delete </v-btn>
        <v-btn
          color="info"
          text
          :disabled="isPostDisabled"
          @click="submit('POST')"
        > Post </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex"


export default {
  name: "LoadboardManager",
  props: {
    loadId: {
      type: [String, Number],
      required: true
    },
    loadboardPostStatuses: {
      type: Array,
      default: () => [],
    },
    loadStatus: {
      type: String,
    }
  },
  watch: {
    menu(val) {
      if (val) {
        this.selected = this.loadboards.reduce((acc, loadboard) => ({
          ...acc,
          [loadboard.name]: loadboard.isEnabled,
        }), {})
      }
    }
  },
  data() {
    return {
      menu: false,
      selected: {}
    }
  },
  computed: {
    ...mapGetters("loadDetail", ["loadboards", "getPerformingLoadboardAction"]),
    statusMap(){ 
      return this.loadboardPostStatuses.reduce((acc, { serviceName: name, ...rest }) => ({
        ...acc,
        [name]: rest
      }), {})
    },
    loadboardState(){
      return this.loadboards.reduce((acc, curr) => ({
        ...acc,
        [curr.name]: {
          ...this.statusMap[curr.name],
          ...curr
        },
      }) , {})
    },
    allSelected(){
      return this.loadboards
        .filter(loadboard => loadboard.isEnabled)
        .every(({ name }) => this.selected[name])
    },
    hasFailedStatus() {
      return Object.values(this.loadboardState).some(({ status }) => (status || "").includes("FAILED"))
    },
    allSuccessStatus() {
      return Object.values(this.loadboardState).every(({ status, isEnabled }) => !isEnabled || status === "POSTED")
    },
    isActionsDisabled() {
      return this.getPerformingLoadboardAction(this.loadId) || Object.values(this.selected).every(value => !value)
    },
    isPostDisabled(){
      return (
        this.isActionsDisabled ||
        // enable only if at least one selected status that is not posted or failed to post
        !Object.values(this.loadboardState).some(({ status, name }) => this.selected[name] && (
          !status ||
          status === "DELETED" ||
          status === "UNPOSTED" ||
          status === "FAILED_TO_POST"
        ))
      )
    },
    isDeleteDisabled() {
      return (
        this.isActionsDisabled ||
        // enable only if there is at least one selected status is POSTED or failed to DELETE/UPDATE
        !Object.values(this.loadboardState).some(
          ({ status, name }) =>
            status && this.selected[name] && (
              status === "POSTED" ||
              status.includes("TO_DELETE") ||
              status.includes("TO_UPDATE")
            )
        )
      )
    },
    isLoadboardsDisabled() {
      return this.loadStatus !== "Available"
    }
  },
  methods: {
    toggleAll(value){
      this.selected = this.loadboards
        .filter(loadboard => loadboard.isEnabled)
        .reduce((acc, curr) => ({
          ...acc,
          [curr.name]: value
        }), {})
    },
    getStatusClass(status){
      if (status.includes("POSTED")){
        return "loadboard-status loadboard-status--success"
      } else if (status.includes("FAILED")){
        return "loadboard-status loadboard-status--danger"
      } else {
        return "loadboard-status loadboard-status--normal"
      }
    },
    submit(action){
      this.$store.dispatch("loadDetail/SUBMIT_LOADBOARD_ACTION", {
        action,
        loadId: this.loadId,
        loadboards: Object.keys(this.selected)
          .filter(serviceName =>
            this.selected[serviceName]
            && (
              (action === "POST" && this.loadboardState[serviceName].status !== "POSTED") ||
              (action === "DELETE" && this.loadboardState[serviceName].status !== "DELETED")
            )
          )
      }).then((statuses) => {
        const responseStatusMapping = statuses.reduce((acc, status) => ({
          ...acc,
          [status.serviceName]: status,
        }) , {})

        const newStatuses = Object.values(this.loadboardState).map(({ name: serviceName, ...rest }) => ({
          ...responseStatusMapping[serviceName] || { serviceName, ...rest },
        }))

        this.$emit("update-statuses", newStatuses)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: unset;
}

.loadboard-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > * {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
  }
  
  .loadboard-status {
    padding: 2px 5px;
    border-radius: 5px;
    text-align: center;
    justify-self: flex-end;

    &--success {
      background-color: #81f085;
    }

    &--danger {
      background-color: #ff626a;
    }

    &--normal {
      background-color: #e6e6e6;
    }
  }
}
</style>