<template>
  <div v-if="showMap" id="map">
    <!--In the following div the HERE Map will render-->
    <div
      id="mapContainer"
      ref="hereMap"
      :style="`height:${height};width:${width}`"
    />
  </div>
  <div
    v-else
    style="
      background: rgba(0, 0, 0, 0.12);
      text-align: center;
      color: rgba(0, 0, 0, 0.26);
      font-size: 25px;
    "
    :style="`height:${height};width:${width};line-height:${height};`"
  >
    Map not available
  </div>
</template>

<script>
export default {
  name: "HereMap",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "300px",
    },
    center: {
      type: Object,
      default: null,
    },
    origin: {
      type: String,
      default: "40.744118,-74.032679",
    },
    destination: {
      type: String,
      default: "40.7280556,-74.0780556",
    },
    wayPoints: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      routeWay: [],
      platform: null,
      mapObject: null,
      apikey: process.env.VUE_APP_HERE_MAP_KEY,
      showMap: true,
    }
  },
  watch: {
    origin() {
      this.calculateRouteFromAtoB()
    },
    destination() {
      this.calculateRouteFromAtoB()
    },
    wayPoints() {
      this.calculateRouteFromAtoB()
    },
  },
  mounted() {
    const platform = new window.H.service.Platform({
      apikey: this.apikey,
    })
    this.platform = platform
    this.initializeHereMap()
  },
  methods: {
    initializeHereMap() {
      const mapContainer = this.$refs.hereMap
      const H = window.H
      // Obtain the default map types from the platform object
      const maptypes = this.platform.createDefaultLayers()

      // Instantiate (and display) a map object:
      this.mapObject = new H.Map(mapContainer, maptypes.vector.normal.truck, {
        zoom: 4,
        center: this.center,
        pixelRatio: window.devicePixelRatio || 1,
        // center object { lat: 40.730610, lng: -73.935242 }
      })

      addEventListener("resize", () => this.mapObject.getViewPort().resize())

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(this.mapObject))

      // add UI
      H.ui.UI.createDefault(this.mapObject, maptypes)
    },
    calculateRouteFromAtoB() {
      if (this.origin.includes("null") || this.destination.includes("null")) {
        this.showMap = false
        return
      }
      let noNull = true
      if (this.wayPoints) {
        for (let i = 0; i < this.wayPoints.length; i++) {
          const cordinate = this.wayPoints[i].split(",")
          if (cordinate[0] === "null" || cordinate[1] === "null") {
            noNull = false
          }
        }
      }
      this.mapObject.removeObjects(this.mapObject.getObjects())
      const router = this.platform.getRoutingService(null, 8)
      const H = window.H
      let routeRequestParams = null

      if (noNull && this.wayPoints) {
        routeRequestParams = {
          routingMode: "fast",
          transportMode: "truck",
          origin: this.origin,
          destination: this.destination,
          return:
            "polyline,turnByTurnActions,actions,instructions,travelSummary",
          via: new H.service.Url.MultiValueQueryParameter(this.wayPoints),
          spans: "truckAttributes",
          avoid: "dirtRoad,tunnel",
        }
      } else {
        routeRequestParams = {
          routingMode: "fast",
          transportMode: "truck",
          origin: this.origin,
          destination: this.destination,
          return:
            "polyline,turnByTurnActions,actions,instructions,travelSummary",
          spans: "truckAttributes",
          avoid: "dirtRoad,tunnel",
        }
      }

      router.calculateRoute(routeRequestParams, this.onSuccess, this.onError)
      this.addMarker(1, this.origin, H)
      this.addMarker(2, this.destination, H)
      if (noNull && this.wayPoints) {
        for (let i = 0; i < this.wayPoints.length; i++) {
          this.addMarker(3, this.wayPoints[i], H)
        }
      }
    },
    onSuccess(result) {
      const route = result.routes[0]
      this.addRouteShapeToMap(route)
    },
    onError() {
      alert("Can't reach the remote server")
    },
    addRouteShapeToMap(route) {
      const H = window.H
      route.sections.forEach((section) => {
        // decode LineString from the flexible polyline
        const linestring = H.geo.LineString.fromFlexiblePolyline(
          section.polyline
        )

        // Create a polyline to display the route:
        const polyline = new H.map.Polyline(linestring, {
          style: {
            lineWidth: 4,
            strokeColor: "rgba(0, 128, 255, 0.7)",
          },
        })

        // Add the polyline to the map
        this.mapObject.addObject(polyline)
        // And zoom to its bounding rectangle
        this.mapObject.getViewModel().setLookAtData({
          bounds: polyline.getBoundingBox(),
          zoom: 5,
        })
      })
    },
    addMarker(type, latlng, H) {
      const cordinate = latlng.split(",")
      let markerIcon = null
      if (type === 2) {
        // Destination
        markerIcon = new H.map.Icon(
          "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-128.png",
          { size: { w: 48, h: 48 } }
        )
      } else if (type === 1) {
        // Origin
        markerIcon = new H.map.Icon(
          "https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/128/MapMarker_Marker_Outside_Chartreuse.png",
          { size: { w: 48, h: 48 } }
        )
      } else {
        // Load Stop
        markerIcon = new H.map.Icon(
          "https://cdn1.iconfinder.com/data/icons/ecommerce-61/48/eccomerce_-_location-128.png",
          { size: { w: 48, h: 48 } }
        )
      }
      const marker = new H.map.Marker(
        { lat: cordinate[0], lng: cordinate[1] },
        { icon: markerIcon }
      )
      this.mapObject.addObject(marker)
    },
  },
}
</script>
