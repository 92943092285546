<template>
  <div>
    {{ renderDate }}
  </div>
</template>
<script>
import moment from "moment"
export default {
  name: "RenderDate",
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  computed: {
    renderDate() {
      return moment(String(this.date)).format("MM-DD-YY")
    },
  },
}
</script>
