export default {
  rules: {
    required: (value) => !!value || "Required",
    counter: (value) => value.length <= 20 || "Max 20 characters",
    amount: (value) => {
      if (!value) return true
      const pattern = /[0-9]+(\.[0-9][0-9]?)?/
      return pattern.test(value) || "Invalid amount"
    },
    alphaNumeric: (value) => {
      if (!value) return true
      const pattern = /^[ A-Za-z0-9:_ @.,()$/#&+-]*$/
      return pattern.test(value) || "Invalid value"
    },
    name: (value) => {
      if (!value) return true
      const pattern = /^[a-zA-Z][',. /\-a-zA-Z]+$/
      return pattern.test(value) || "Invalid name"
    },
    phone: (value) => {
      if (!value) return true
      const pattern =
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9,a-z ]{4,15})$/
      return pattern.test(value) || "Invalid phone number"
    },
    email: (value) => {
      if (!value) return true
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || "Invalid email"
    },
    postalCode: (value) => {
      if (!value) return true
      const pattern = /^[A-Za-z0-9- ]{5,10}$/
      return pattern.test(value) || "Invalid postal code"
    },
    floatDigit: (value) => {
      if (!value) return true
      const pattern = /^-?\d+(\.\d+)?$/
      return pattern.test(value) || "Invalid value"
    },
    alphaNumericWithComma: (value) => {
      const pattern = /^\s*|[A-Za-z0-9,]*$/
      return pattern.test(value) || "Invalid value"
    },
    intDigit: (value) => {
      if (!value) return true
      const pattern = /^[0-9]{1,16}$/
      return pattern.test(value) || "Invalid value, only allows digits."
    },
    time: (value) => {
      const pattern = /^(([0-1]?[0-9]|2[0-4]):([0-5][0-9])?)?$/
      return pattern.test(value) || "Invalid time"
    },
  },
}
