<template>
  <div>
    <!-- filter -->
    <v-container class="pb-0" fluid style="max-width: 1600px">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header data-cy="expand">
            Search
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-select
                  v-model="searchParam.loadStatusFilter"
                  :items="loadStatusAll"
                  clearable
                  data-cy="load-status"
                  item-text="name"
                  item-value="id"
                  label="Load Status"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <customer-auto-complete
                  class="pt-3"
                  data-cy="customer"
                  @event="selectedCustomer"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <carrier-auto-complete
                  carrier-text="Carrier"
                  class="pt-3"
                  data-cy="carrier"
                  @event="selectedCarrier"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" sm="6" md="2" lg="2" xl="2">
                <v-text-field
                  v-model="searchParam.carrierInvoiceNumber"
                  type="text"
                  label="Carrier Invoice Number"
                  @keydown.enter="searchAllLoads()"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-select
                  v-model="searchParam.equipmentFilter"
                  :items="equipmentTypes"
                  clearable
                  data-cy="equipment"
                  item-text="name"
                  item-value="id"
                  label="Equipment Type"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <agent-auto-complete
                  agent-text="Agent Name"
                  data-cy="agent"
                  @event="selectedAgent"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <carrier-sales-rep-auto-complete
                  data-cy="carrier-sales"
                  @event="selectedCSR"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" sm="6" md="2" lg="2" xl="2">
                <v-select
                  v-model="searchParam.hold"
                  :items="holdItems"
                  clearable
                  data-cy="hold"
                  item-text="name"
                  item-value="id"
                  label="Hold"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" sm="6" md="2" lg="2" xl="2">
                <v-select
                  v-model="searchParam.orderType"
                  :items="orderTypes"
                  clearable
                  data-cy="order-type"
                  item-text="name"
                  item-value="id"
                  label="Order Type"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-text-field
                  v-model="searchParam.loadNumberFilter"
                  data-cy="load-number"
                  label="Load Number"
                  type="text"
                  @keydown.enter="searchAllLoads()"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-text-field
                  v-model="searchParam.CustomerRefId"
                  data-cy="ref-id"
                  label="Customer Ref Id"
                  type="text"
                  @keydown.enter="searchAllLoads()"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-text-field
                  v-model="searchParam.poNumberFilter"
                  data-cy="po-number"
                  label="PO Number"
                  type="text"
                  @keydown.enter="searchAllLoads()"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-text-field
                  v-model="searchParam.puNumberFilter"
                  data-cy="pu-number"
                  label="PU Number"
                  type="text"
                  @keydown.enter="searchAllLoads()"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-text-field
                  v-model="searchParam.commodityFilter"
                  data-cy="commodity"
                  label="Commodity"
                  type="text"
                  @keydown.enter="searchAllLoads()"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" sm="6" md="2" lg="2" xl="2">
                <v-text-field
                  v-model="searchParam.originFilter.city"
                  data-cy="origin-city"
                  label="Origin City"
                  type="text"
                  @keydown.enter="searchAllLoads()"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-text-field
                  v-model="searchParam.originFilter.state"
                  data-cy="origin-state"
                  hint="Short state name (Ex. Texas - TX)"
                  label="Origin State"
                  persistent-hint
                  type="text"
                  @keydown.enter="searchAllLoads()"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-menu
                  v-model="pickupCalendar"
                  :close-on-content-click="false"
                  data-cy="pickup-date"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="pickupDateCalender"
                      label="Pickup Date"
                      persistent-hint
                      readonly
                      data-cy="pickup-date-field"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchParam.pickupDate"
                    no-title
                    data-cy="pickup-date-picker"
                    @input="pickupCalendar = false"
                  />
                </v-menu>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-text-field
                  v-model="searchParam.destinationFilter.city"
                  data-cy="destination-city"
                  label="Destination City"
                  type="text"
                  @keydown.enter="searchAllLoads()"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-text-field
                  v-model="searchParam.destinationFilter.state"
                  data-cy="destination-state"
                  hint="Short state name (Ex. Texas - TX)"
                  label="Destination State"
                  persistent-hint
                  type="text"
                  @keydown.enter="searchAllLoads()"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                <v-menu
                  v-model="deliveryCalendar"
                  :close-on-content-click="false"
                  offset-y
                  transition="scale-transition"
                  data-cy="delivery-date-picker"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="deliveryDateCalender"
                      label="Delivery Date"
                      persistent-hint
                      readonly
                      data-cy="delivery-date-field"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchParam.deliveryDate"
                    no-title
                    data-cy="delivery-date-field"
                    @input="deliveryCalendar = false"
                  />
                </v-menu>
              </v-col>
                <v-col class="pt-0 pb-0" cols="12" lg="2" md="2" sm="6" xl="2">
                    <account-manager-auto-complete
                        ref="account-manager"
                        color="#e1b753"
                        :default-manager="searchParam.manager"
                        @event="selectedAccountManager"
                        @blur="selectedAccountManager"
                    />
                </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="flex">
                <Button primary class="mr-2" @click="searchAllLoads()">
                  Filter
                </Button>

                <Button class="mr-2" @click="resetSearch()">Reset</Button>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <!-- / filter -->

    <!-- loads table -->
    <v-container fluid class="pt-0 pb-0" style="max-width: 1600px">
      <load-tracking />
      <v-card class="pt-0 pb-0">
        <v-row>
          <v-col
            :cols="$vuetify.breakpoint.mdAndUp ? columnNumber : 12"
            class="pt-0"
          >
            <v-tabs v-model="currentTab" grow @change="changeTab">
              <v-tab
                v-for="i in tabs"
                :key="i.href"
                :disabled="loadingIcon"
                :href="`#tab-${i.href}`"
              >
                {{ i.title }}
                <span v-if="currentTab.split('-')[1] === i.href.toString()"
                  >({{ i.count }})</span
                >
              </v-tab>
            </v-tabs>
            <v-tabs-items
              id="tabData"
              v-model="currentTab"
              style="overflow-y: auto; max-height: 680px"
            >
              <v-tab-item
                v-for="i in tabs"
                :key="i.href"
                :value="`tab-${i.href}`"
              >
                <v-data-table
                  :id="`loadsTable-${i.href}`"
                  :headers="headers"
                  :item-class="itemRowBackground"
                  :items="loadItems"
                  :items-per-page="pageSize"
                  :loading="loadingIcon"
                  calculate-widths
                  fixed-header
                  hide-default-footer
                >
                  <template v-slot:item.customer="{ item }">
                    <p
                      style="text-align: center; cursor: pointer"
                      @click="loadClick(item)"
                    >
                      {{ item.customer }}
                    </p>
                  </template>
                  <template v-slot:item.loadNumber="{ item }">
                    <v-btn
                      :to="{
                        name: 'Load Detail',
                        params: { id: item.loadNumber },
                      }"
                      small
                      target="_blank"
                      text
                    >
                      {{ item.loadNumber }}
                    </v-btn>
                    <br />
                    <tooltip
                      v-if="item.customerReferenceId"
                      :tooltip-content="`Ref: ${item.customerReferenceId}`"
                      type="td"
                    />
                  </template>
                  <template v-slot:item.loadStatus="{ item }">
                    <load-status-button
                      :agent-detail="agentDetail"
                      :load-status-items="loadStatusChoices"
                      :shipment-detail="item"
                    />
                  </template>
                  <template v-slot:item.carrier="{ item }">
                    <p v-if="item.carrier">
                      <router-link
                        :to="{
                          name: 'Carrier Detail',
                          params: {
                            id: item.carrier.id,
                            title: `${item.carrier.name} - ${item.carrier.mc_number}`,
                          },
                        }"
                      >
                        <span style="color: #464646">
                          {{ item.carrier.name }}
                        </span>
                      </router-link>
                    </p>
                  </template>
                  <template v-slot:item.loadboards="{ item }">
                    <loadboard-manager
                      :load-id="item.loadNumber"
                      :loadboard-post-statuses="item.loadboardPostStatuses"
                      :load-status="item.loadStatus"
                      @update-statuses="handleUpdatedStatuses(item.loadNumber, $event)"
                    />
                  </template>
                  <template v-slot:item.cost="{ item }">
                    <p>
                      <span v-if="item.cost.min">Min: </span>
                      <span v-if="item.cost.min" class="blue--text text--darken"
                        >${{ item.cost.min }}</span
                      >
                      <br />
                      <span v-if="item.cost.max">Max: </span>
                      <span v-if="item.cost.max" class="blue--text text--darken"
                        >${{ item.cost.max }}</span
                      >
                    </p>
                  </template>
                  <!-- origin column -->
                  <template v-slot:item.shipper="{ item }">
                    <p v-if="item.shipper !== null" class="mb-0">
                      {{
                        formatLocation(
                          item.shipper.city,
                          item.shipper.state_province_region
                        )
                      }}
                      <br />
                      <template v-if="item.pickupDate !== null">
                        {{ formatDate(item.pickupDate) }}
                      </template>
                      <template v-if="item.pickupEarlyTime !== null">
                        {{ parseTime(item.pickupEarlyTime) }}
                        -
                        {{ parseTime(item.pickupLateTime) }}
                      </template>
                    </p>
                  </template>
                  <!-- / origin column -->
                  <!-- destination column -->
                  <template v-slot:item.consignee="{ item }">
                    <p v-if="item.consignee !== null" class="mb-0">
                      {{
                        formatLocation(
                          item.consignee.city,
                          item.consignee.state_province_region
                        )
                      }}
                      <br />
                      <template v-if="item.deliveryDate !== null">
                        {{ formatDate(item.deliveryDate) }}
                      </template>
                      <template v-if="item.deliveryEarlyTime !== null">
                        {{ parseTime(item.deliveryEarlyTime) }}
                        -
                        {{ parseTime(item.deliveryLateTime) }}
                      </template>
                    </p>
                  </template>
                  <!-- / destination column -->
                </v-data-table>

                <v-page
                  :page-size-menu="[50, 100]"
                  :total-row="totalRecord"
                  class="pt-3 pr-3"
                  @page-change="readDataFromAPI"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col v-if="showDetail && $vuetify.breakpoint.mdAndUp" cols="4">
            <v-card class="mt-0" light style="height: 730px; overflow-y: auto">
              <shipment-info />
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div
                      class="rounded"
                      style="position: relative; border: 1px solid #d6d8d9"
                    >
                      <tender-detail :show-edit="false" />
                    </div>
                    <div
                      class="mt-3 rounded"
                      style="position: relative; border: 1px solid #d6d8d9"
                    >
                      <shipper-info
                        :hgt-class="heightClass"
                        :show-edit="false"
                      />
                    </div>
                    <div
                      class="mt-3 rounded"
                      style="position: relative; border: 1px solid #d6d8d9"
                    >
                      <consignee-info
                        :hgt-class="heightClass"
                        :show-edit="false"
                      />
                    </div>
                    <div
                      class="mt-3 rounded"
                      style="position: relative; border: 1px solid #d6d8d9"
                    >
                      <commodity-dimensions
                        :hgt-class="heightClass"
                        :show-edit="false"
                      />
                    </div>
                    <div
                      class="mt-3 rounded"
                      style="position: relative; border: 1px solid #d6d8d9"
                    >
                      <carrier-info
                        :hgt-class="heightClass"
                        :show-edit="false"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!-- / loads table -->
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"
import common from "@/utils/common"
import Button from "@/views/components/Button"
import CustomerAutoComplete from "@/views/pages/components/CustomerAutoComplete"
import TenderDetail from "@/views/pages/load-detail-components/TenderDetail"
import ShipperInfo from "@/views/pages/load-detail-components/ShipperInfo"
import ConsigneeInfo from "@/views/pages/load-detail-components/ConsigneeInfo"
import CommodityDimensions from "@/views/pages/load-detail-components/CommodityDimensions"
import CarrierInfo from "@/views/pages/load-detail-components/CarrierInfo"
import CarrierSalesRepAutoComplete from "@/views/pages/components/CarrierSalesRepAutoComplete"
import ShipmentInfo from "@/views/pages/load-detail-components/ShipmentInfo"
import LoadTracking from "@/views/pages/LoadTracking"
import CarrierAutoComplete from "@/views/pages/components/CarrierAutoComplete"
import AgentAutoComplete from "@/views/pages/components/AgentAutoComplete"
import Tooltip from "@/views/pages/components/Tooltip"
import LoadStatusButton from "@/views/pages/components/LoadStatusButton"
import AccountManagerAutoComplete from "@/views/pages/components/AccountManagerAutoComplete"
import LoadboardManager from './components/LoadboardManager'

export default {
  name: "AllLoad",
  components: {
    Button,
    CustomerAutoComplete,
    TenderDetail,
    ShipperInfo,
    ConsigneeInfo,
    CommodityDimensions,
    CarrierInfo,
    CarrierSalesRepAutoComplete,
    ShipmentInfo,
    LoadTracking,
    CarrierAutoComplete,
    AgentAutoComplete,
    Tooltip,
    LoadStatusButton,
    AccountManagerAutoComplete,
    LoadboardManager,
  },
  data() {
    return {
      showDetail: false,
      heightClass: "",
      queryString: "",
      currentLoadNum: 0,
      columnNumber: 12,
      searchParam: {
        loadStatusFilter: null,
        equipmentFilter: null,
        CustomerRefId: null,
        carrierSalesRepFilter: null,
        originFilter: {
          city: null,
          state: null,
        },
        destinationFilter: {
          city: null,
          state: null,
        },
        carrierFilter: null,
        loadNumberFilter: null,
        pickupDate: null,
        deliveryDate: null,
        poNumberFilter: null,
        puNumberFilter: null,
        commodityFilter: null,
        orderType: null,
        customerId: null,
        carrierId: null,
        csrId: null,
        agentId: null,
        hold: null,
        manager: null,
      },
      carrierSalesRepList: [],
      carrierList: [],
      pickupCalendar: false,
      deliveryCalendar: false,
      scrollPosition: 0,
      currentTab: "tab-4",
      tabs: [
        {
          title: "All",
          href: 1,
          content: [],
          count: 0,
        },
        {
          title: "Plan",
          href: 2,
          content: [],
          count: 0,
        },
        {
          title: "Spot",
          href: 3,
          content: [],
          count: 0,
        },
        {
          title: "Tender",
          href: 4,
          content: [],
          count: 0,
        },
        {
          title: "My Loads",
          href: 5,
          content: [],
          count: 0,
        },
        {
          title: "Archived",
          href: 6,
          content: [],
          count: 0,
        },
      ],
      headers: [
        {
          text: "Customer",
          align: "center",
          sortable: false,
          value: "customer",
        },
        {
          text: "Load Number",
          value: "loadNumber",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "Load Status",
          value: "loadStatus",
          align: "center",
          sortable: false,
        },
        {
          text: "Carrier",
          value: "carrier",
          align: "center",
          sortable: false,
        },
        {
          text: "Loadboards",
          value: "loadboards",
          align: "center",
          sortable: false,
        },
        { text: "CSR", value: "csr", align: "center", sortable: false },
        {
          text: "Equipment",
          value: "equipment",
          align: "center",
          sortable: false,
        },
        {
          text: "Origin",
          value: "shipper",
          align: "center",
          sortable: false,
        },
        {
          text: "Destination",
          value: "consignee",
          align: "center",
          sortable: false,
        },
        {
          text: "Costs",
          value: "cost",
          align: "center",
          sortable: false,
        },
      ],
      loadItems: [],
      queryParams: {},
      totalRecord: 0,
      pageSize: 50,
      numberOfPages: 0,
      page: 1,
      loadStatusItems: [],
      holdItems: [
        {
          id: 1,
          name: "Yes",
        },
        {
          id: 2,
          name: "No",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("loadDetail", [
      "loadList",
      "loadingIcon",
      "loadStatusUpdate",
      "loadStatusChoices",
      "loadboards"
    ]),
    ...mapGetters("generic", [
      "equipmentTypes",
      "customerId",
      "carrierId",
      "orderTypes",
      "agentDetail",
    ]),
    pickupDateCalender() {
      return this.formatDate(this.searchParam.pickupDate)
    },
    deliveryDateCalender() {
      return this.formatDate(this.searchParam.deliveryDate)
    },
    loadStatusAll() {
      const status = []
      for (let i = 0; i < this.loadStatusChoices.length; i++) {
        const objStatus = {
          id: this.loadStatusChoices[i].id,
          name: this.loadStatusChoices[i].name,
        }
        status.push(objStatus)
      }
      return status
    },
  },
  watch: {
    loadList() {
      if (this.loadItems.length === 0) {
        for (let i = 0; i < this.tabs.length; i++) {
          this.tabs[i].count = 0
        }
        this.tabs[this.currentTab.split("-")[1] - 1].count = this.loadList.count
      }
      this.appendLoadData()
    },
    loadStatusUpdate() {
      if (this.loadStatusUpdate !== null) {
        const idx = this.loadItems.findIndex(
          (x) => x.loadNumber === this.loadStatusUpdate.id
        )
        this.loadItems[idx].loadStatusChoices =
          this.loadStatusUpdate.loadStatusDisplay
      }
    },
  },
  mounted() {
    this.loadItems = []
    this.changeTab("tab-4")
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    resetSearch() {
      this.searchParam.loadStatusFilter = null
      this.searchParam.equipmentFilter = null
      this.searchParam.CustomerRefId = null
      this.searchParam.carrierSalesRepFilter = null
      this.searchParam.originFilter.city = null
      this.searchParam.originFilter.state = null
      this.searchParam.destinationFilter.city = null
      this.searchParam.destinationFilter.state = null
      this.searchParam.carrierFilter = null
      this.searchParam.loadNumberFilter = null
      this.searchParam.pickupDate = null
      this.searchParam.deliveryDate = null
      this.searchParam.poNumberFilter = null
      this.searchParam.puNumberFilter = null
      this.searchParam.commodityFilter = null
      this.searchParam.orderType = null
      this.searchParam.customerId = null
      this.searchParam.carrierId = null
      this.searchParam.carrierInvoiceNumber = null
      this.searchParam.csrId = null
      this.$store.commit("generic/setCustomerId", null)
      this.$store.commit("carrier/setCarrierId", null)
      this.$store.commit("loadDetail/setCustomerCompany", null)
      this.$store.commit("generic/setCSRDetail", null)
      this.showDetail = false
      this.columnNumber = 12
      this.currentLoadNum = 0
      this.searchParam.hold = null
        this.searchParam.manager = null
      this.changeTab(this.currentTab)
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split("-")
      return `${month}/${day}/${year.toString().substr(-2)}`
    },
    parseTime(time) {
      if (!time) {
        return null
      }
      return moment(time, "HH:mm").format("HH:mm")
    },
    loadClick(value) {
      if (this.currentLoadNum !== value.loadNumber) {
        this.removeSelectedClass()
        this.addSelectedClass(value.loadNumber)
        this.showDetail = true
        this.columnNumber = 8
        this.currentLoadNum = value.loadNumber
        this.getLoadDetail(value.loadNumber)
      } else {
        this.removeSelectedClass()
        this.showDetail = false
        this.columnNumber = 12
        this.currentLoadNum = 0
      }
    },
    getLoadDetail(loadId) {
      this.$store.dispatch("loadDetail/SET_LOAD_ID", loadId)
      this.$store.dispatch("loadDetail/GET_LOAD_DETAIL", loadId)
    },
    getLoads() {
      this.queryParams = {}
      this.queryParams.limit = 20
      this.queryParams.offset = 0
      this.queryParams.ordering = "pickup_date"
      this.$store.dispatch("loadDetail/GET_ALL_LOADS", this.queryParams)
    },
    appendLoadData() {
      this.loadItems = this.loadList.results
      this.totalRecord = this.loadList.count
      this.numberOfPages = Math.ceil(this.loadList.count / this.pageSize)
    },
    searchAllLoads() {
      this.loadItems = []
      this.changeTab(this.currentTab)
    },
    createQueryString() {
      this.queryParams = {}
      this.queryParams.limit = 50
      this.queryParams.offset = 0
      this.queryParams.ordering = "pickup_date"
      if (this.searchParam.loadNumberFilter) {
        this.queryParams.id = this.searchParam.loadNumberFilter
      }
      if (this.searchParam.originFilter.city) {
        this.queryParams.shipper__city__icontains =
          this.searchParam.originFilter.city
      }
      if (this.searchParam.originFilter.state) {
        this.queryParams.shipper__state_province_region__iexact =
          this.searchParam.originFilter.state
      }
      if (this.searchParam.destinationFilter.city) {
        this.queryParams.consignee__city__icontains =
          this.searchParam.destinationFilter.city
      }
      if (this.searchParam.destinationFilter.state) {
        this.queryParams.consignee__state_province_region__iexact =
          this.searchParam.destinationFilter.state
      }
      if (this.searchParam.CustomerRefId) {
        this.queryParams.customer_reference_id__icontains =
          this.searchParam.CustomerRefId
      }
      if (this.searchParam.pickupDate) {
        this.queryParams.pickup_date = this.searchParam.pickupDate
      }
      if (this.searchParam.deliveryDate) {
        this.queryParams.delivery_date = this.searchParam.deliveryDate
      }
      if (this.searchParam.poNumberFilter) {
        this.queryParams.po_number__icontains = this.searchParam.poNumberFilter
      }
      if (this.searchParam.puNumberFilter) {
        this.queryParams.pu_number__icontains = this.searchParam.puNumberFilter
      }
      if (this.searchParam.loadStatusFilter) {
        this.queryParams.load_status = this.searchParam.loadStatusFilter
      }
      if (this.searchParam.equipmentFilter) {
        this.queryParams.equipment_type = this.searchParam.equipmentFilter
      }
      if (this.searchParam.csrId) {
        this.queryParams.carrier_sales_rep = this.searchParam.csrId
      }
      if (this.searchParam.customerId) {
        this.queryParams.customer_company = this.searchParam.customerId
      }
      if (this.searchParam.commodityFilter) {
        this.queryParams.commodity__icontains = this.searchParam.commodityFilter
      }
      if (this.searchParam.carrierId) {
        this.queryParams.carrier = this.searchParam.carrierId
      }
      if (this.searchParam.carrierInvoiceNumber) {
        this.queryParams.carrier_invoice_number =
          this.searchParam.carrierInvoiceNumber
      }
      if (this.searchParam.orderType) {
        this.queryParams.order_type = this.searchParam.orderType
      }
      if (this.searchParam.agentId) {
        this.queryParams.agent = this.searchParam.agentId
      }
      if (this.searchParam.hold) {
        this.queryParams.hold = this.searchParam.hold === 1
      }
        if (this.searchParam.manager) {
            this.queryParams.customer_company__account_manager = this.searchParam.manager.id
        }
    },
    changeTab(value) {
      this.page = 1
      this.loadItems = []
      if (value === "tab-1") {
        // All
        this.createQueryString()
        this.queryParams.archived = false
      } else if (value === "tab-3") {
        // SPOT
        this.createQueryString()
        this.queryParams.archived = false
        this.queryParams.order_type = 1
        this.queryParams.load_status__in = "2,10,11,12,13"
      } else if (value === "tab-2") {
        // PLAN
        this.createQueryString()
        this.queryParams.archived = false
        this.queryParams.order_type__in = "1,2,3"
        this.queryParams.load_status__in = "1"
      } else if (value === "tab-4") {
        // TENDER
        this.createQueryString()
        this.queryParams.archived = false
        this.queryParams.order_type__in = "2,3"
        this.queryParams.load_status__in = "2,3,4,5,10,11,12,13,15,16,17"
      } else if (value === "tab-5") {
        // MY LOAD
        this.createQueryString()
        this.queryParams.archived = false
        this.queryParams.carrier_sales_rep = this.agentDetail.user.id
        this.queryParams.order_type__in = "2,3"
        this.queryParams.load_status__in = "1,2,3,4,5,10,11,12,13,15,16,17"
      } else if (value === "tab-6") {
        // ARCHIVED
        this.createQueryString()
        this.queryParams.archived = true
      }
      this.$store.dispatch("loadDetail/GET_ALL_LOADS", this.queryParams)
      this.showDetail = false
      this.columnNumber = 12
      this.currentLoadNum = 0
    },
    updatePostStatus(item, itemPosted, loadId) {
      const payload = {
        loadId: loadId,
        posted: itemPosted,
      }
      this.$store
        .dispatch("loadDetail/UPDATE_LOADBOARD_STATUS", payload)
        .then(() => {
          const idx = this.loadItems.findIndex((x) => x.loadNumber === loadId)
          this.loadItems[idx].posted = itemPosted
        })
        .catch(() => {
          const idx = this.loadItems.findIndex((x) => x.loadNumber === loadId)
          this.loadItems[idx].posted = !itemPosted
        })
    },
    selectedCustomer(value) {
      if (value.customer) {
        if (value.flag === 1) {
          this.searchParam.customerId = value.customer.id
          this.searchAllLoads()
        } else {
          this.searchParam.customerId = value.customer.id
        }
      }
    },
    selectedCarrier(value) {
      if (value.carrier) {
        if (value.flag === 1) {
          this.searchParam.carrierId = value.carrier.id
          this.searchAllLoads()
        } else {
          this.searchParam.carrierId = value.carrier.id
        }
      }
    },
    selectedCSR(value) {
      if (value.csr) {
        if (value.flag === 1) {
          this.searchParam.csrId = value.csr.id
          this.searchAllLoads()
        } else {
          this.searchParam.csrId = value.csr.id
        }
      }
    },
    readDataFromAPI(value) {
      if (this.loadItems.length > 0) {
        this.loading = true
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        this.$store.dispatch("loadDetail/GET_ALL_LOADS", this.queryParams)
      }
    },
    itemRowBackground: function (item) {
      return item.selectedClass ? item.selectedClass : ""
    },
    addSelectedClass(loadNumber) {
      const idx = this.loadItems.findIndex((x) => x.loadNumber === loadNumber)
      if (idx > -1) {
        this.loadItems[idx].selectedClass = "high-light-row"
      }
    },
    removeSelectedClass() {
      const idx = this.loadItems.findIndex(
        (x) => x.selectedClass === "high-light-row"
      )
      if (idx > -1) {
        this.loadItems[idx].selectedClass = null
      }
    },
    selectedAgent(value) {
      if (value.agent) {
        if (value.flag === 1) {
          this.searchParam.agentId = value.agent.id
          this.searchAllLoads()
        } else {
          this.searchParam.agentId = value.agent.id
        }
      }
    },
    selectedAccountManager(value) {
      this.searchParam.manager = value.manager
    },
    handleUpdatedStatuses(loadId, statuses) {
      this.loadItems = this.loadItems.map((item) => {
        if (item.loadNumber === loadId) {
          item.loadboardPostStatuses = statuses
        }
        return item
      })
    }
  },
}
</script>

<style scoped>
.rounded-circle {
  border-radius: 50% !important;
}
.tender-detail-bottom-box {
  border: 1px solid #64b5f6;
  border-radius: 0 4px 4px 4px;
  bottom: 0;
  position: absolute;
}
.box-header {
  font-size: 16px;
  font-weight: 500;
}
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.v-application a {
  color: #000;
  text-decoration: none;
}
.v-data-table table tbody tr td .v-btn,
.v-data-table table tbody tr td span {
  font-size: 12px !important;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: red !important;
  color: #ffffff !important;
}

.v-input__slot {
  align-items: center;
  justify-content: center;
}
</style>

<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.high-light-row {
  background-color: #eeeeee !important;
}
</style>
