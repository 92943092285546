<template>
  <div>
    <v-dialog v-model="isLoadTrackingDialogOpen" persistent width="500">
      <v-card :key="loadTrackingDialogId">
        <v-card-title style="background-color: #1e88e5">
          <v-row class="align-center">
            <v-col cols="12">
              <span class="text-h2 white--text">Tracking Update</span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-progress-linear v-if="loadingIcon" indeterminate />

        <v-card-text class="pt-4">
          <v-form ref="load-tracking-form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="text-center" cols="12">
                  <load-status-edit-button
                    :disabled="disableStatusElement"
                    :load-status="
                      shipmentDetailCopy && shipmentDetailCopy.loadStatusDisplay
                    "
                    :shipment-id="shipmentDetailCopy && shipmentDetailCopy.id"
                    @change="setLoadStatus"
                  />
                </v-col>

                <v-col class="pt-0" cols="12" md="6">
                  <date-time-picker
                    :datetime.sync="pickupApptAt"
                    label="Pickup Appointment"
                    @input="onDateTimeChange($event, 'pickupApptAt')"
                  />
                </v-col>
                <v-col class="pt-0" cols="12" md="6">
                  <date-time-picker
                    :datetime.sync="deliveryApptAt"
                    label="Delivery Appointment"
                    @input="onDateTimeChange($event, 'deliveryApptAt')"
                  />
                </v-col>

                <v-col class="pt-0" cols="12" md="6">
                  <date-time-picker
                    :datetime.sync="arrivedAtShipperAt"
                    :required="isArrivedAtShipperRequired"
                    label="Arrived at Shipper"
                    @input="onDateTimeChange($event, 'arrivedAtShipperAt')"
                  />
                </v-col>
                <v-col class="pt-0" cols="12" md="6">
                  <date-time-picker
                    :datetime.sync="arrivedAtConsigneeAt"
                    :required="isArrivedAtConsigneeRequired"
                    label="Arrived at Consignee"
                    @input="onDateTimeChange($event, 'arrivedAtConsigneeAt')"
                  />
                </v-col>

                <v-col class="pt-0" cols="12" md="6">
                  <date-time-picker
                    :datetime.sync="pickedAt"
                    :required="isPickedAtRequired"
                    label="Loaded"
                    @input="onDateTimeChange($event, 'pickedAt')"
                  />
                </v-col>
                <v-col v-if="shipmentDetail" class="pt-0" cols="12" md="6">
                  <date-time-picker
                    :datetime.sync="deliveredAt"
                    label="Delivered"
                    @input="onDateTimeChange($event, 'deliveredAt')"
                  />
                </v-col>

                <v-col class="pt-0" cols="12" md="6">
                  <v-checkbox
                    v-model="trackingUpdate.onTimePick"
                    class="mt-0"
                    color="success"
                    hide-details
                    label="On Time Pick"
                  />
                </v-col>
                <v-col class="pt-0" cols="12" md="6">
                  <v-checkbox
                    v-model="trackingUpdate.onTimeDelivery"
                    class="mt-0"
                    color="success"
                    hide-details
                    label="On Time Delivery"
                  />
                </v-col>

                <v-col class="pt-0" cols="12" md="6">
                  <v-text-field v-model="city" label="City" type="text" />
                </v-col>
                <v-col class="pt-0" cols="12" md="6">
                  <v-text-field
                    v-model="state"
                    :rules="[rules.state]"
                    hint="Short Name (TX for Texas)"
                    label="State Province Region"
                    type="text"
                  />
                </v-col>

                <v-col class="text-center text-h4 pb-0" cols="12">
                  EDI Update
                </v-col>
                <v-col v-if="loadStopData.length > 0" class="py-0" cols="12">
                  <v-select
                    v-model="loadStopId"
                    :items="loadStopData"
                    clearable
                    item-text="text"
                    item-value="value"
                    label="Load Stop"
                  />
                </v-col>
                <v-col class="pt-0" cols="12" md="6">
                  <v-select
                    v-model="ediEventType"
                    :items="ediEventTypeOptions"
                    item-text="text"
                    item-value="value"
                    label="EDI Event Type"
                    @change="loadReasonCode"
                  />
                </v-col>
                <v-col class="pt-0" cols="12" md="6">
                  <v-select
                    v-model="ediReasonCode"
                    :items="ediReasonCodeData"
                    item-text="text"
                    item-value="value"
                    label="EDI Reason Code"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn color="gray" text @click="closeDialog()">Close</v-btn>
          <v-btn color="primary" text @click="submitLoad()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment"
import cloneDeep from "lodash/cloneDeep"
import { mapGetters } from "vuex"
import LoadStatusEditButton from "@/views/pages/load-detail-components/edit-components/LoadStatusEditButton"
import DateTimePicker from "@/views/pages/components/DateTimePicker"
import common from "@/utils/common"

const EDI_EVENT_TYPES_IDS = {
  PICKUP_APPOINTMENT: 1,
  DELIVERY_APPOINTMENT: 2,
  ARRIVED_AT_SHIPPER: 3,
  LOADED: 4,
  CHECK_CALL: 5,
  ARRIVED_AT_CONSIGNEE: 6,
  DELIVERED: 7,
  ESTIMATED_DELIVERY: 8,
}

const EDI_REASON_CODES_IDS = {
  MISSED_DELIVERY: 1,
  ACCIDENT: 2,
  CONSIGNEE_RELATED: 3,
  MECHANICAL_BREAKDOWN: 4,
  OTHER_CARRIER_RELATED: 5,
  PREVIOUS_STOP: 6,
  SHIPPER_RELATED: 7,
  WEATHER_OR_NATURAL_DISASTER: 8,
  MISSED_PICKUP: 9,
  HELD_PER_SHIPPER: 10,
  EXCEEDS_SERVICE_LIMITATIONS: 11,
}

const LOAD_STATUS_IDS = {
  PICKED: 5,
  LOADING: 16,
  UNLOADING: 17,
}

export default {
  name: "LoadTracking",
  components: {
    DateTimePicker,
    LoadStatusEditButton,
  },
  data() {
    return {
      valid: false,
      ediEventType: null,
      ediEventTypeOptions: [
        {
          text: "Pickup Appointment",
          value: EDI_EVENT_TYPES_IDS.PICKUP_APPOINTMENT,
        },
        {
          text: "Delivery Appointment",
          value: EDI_EVENT_TYPES_IDS.DELIVERY_APPOINTMENT,
        },
        {
          text: "Estimated Delivery",
          value: EDI_EVENT_TYPES_IDS.ESTIMATED_DELIVERY,
        },
        {
          text: "Arrived at Shipper",
          value: EDI_EVENT_TYPES_IDS.ARRIVED_AT_SHIPPER,
        },
        {
          text: "Loaded",
          value: EDI_EVENT_TYPES_IDS.LOADED,
        },
        {
          text: "Check Call",
          value: EDI_EVENT_TYPES_IDS.CHECK_CALL,
        },
        {
          text: "Arrived at Consignee",
          value: EDI_EVENT_TYPES_IDS.ARRIVED_AT_CONSIGNEE,
        },
        {
          text: "Delivered",
          value: EDI_EVENT_TYPES_IDS.DELIVERED,
        },
      ],
      loadStopData: [],
      ediReasonCode: null,
      ediReasonCodeData: [],
      dateProps: {
        headerColor: "blue",
        color: "blue",
      },
      timeProps: {
        color: "blue",
        headerColor: "blue",
        useSeconds: false,
        ampmInTitle: true,
      },
      city: null,
      state: null,
      rules: {
        state: (value) => {
          if (!value) {
            return true
          }
          const pattern = /^[A-Za-z]{0,2}$/
          return (
            pattern.test(value) || "Enter a valid two character state code."
          )
        },
      },
      loadStatus: null,
      shipmentDetailCopy: null,
      loadStopId: null,
      pickupApptAt: "",
      deliveryApptAt: "",
      arrivedAtShipperAt: "",
      arrivedAtConsigneeAt: "",
      pickedAt: "",
      deliveredAt: "",
    }
  },

  computed: {
    ...mapGetters("loadDetail", [
      "isLoadTrackingDialogOpen",
      "loadTrackingDialogId",
      "shipmentDetail",
      "loadingIcon",
      "trackingUpdate",
    ]),
    ...mapGetters("generic", ["agentDetail"]),
    disableStatusElement() {
      return common.methods.disableButtonBaseOnLoadStatus(
        this.shipmentDetail,
        this.agentDetail
      )
    },
    disableDeliveredDate() {
      const DELIVERED_LOAD_STATUS_ID = 6
      if (
        this.agentDetail.accounting ||
        this.agentDetail.admin ||
        this.shipmentDetail.loadStatus !== DELIVERED_LOAD_STATUS_ID
      ) {
        return false
      } else {
        return true
      }
    },

    isArrivedAtShipperRequired() {
      return (
        this.loadStatus?.loadStatus === LOAD_STATUS_IDS.LOADING &&
        !this.trackingUpdate.arrivedAtShipperAt
      )
    },

    isPickedAtRequired() {
      return (
        this.loadStatus?.loadStatus === LOAD_STATUS_IDS.PICKED &&
        !this.trackingUpdate.pickedAt
      )
    },

    isArrivedAtConsigneeRequired() {
      return (
        this.loadStatus?.loadStatus === LOAD_STATUS_IDS.UNLOADING &&
        !this.trackingUpdate.arrivedAtConsigneeAt
      )
    },
  },

  watch: {
    loadTrackingDialogId() {
      if (this.loadTrackingDialogId) {
        this.$store.dispatch(
          "loadDetail/GET_LOAD_DETAIL",
          this.loadTrackingDialogId
        )
        this.ediReasonCodeData = []
      }
    },

    shipmentDetail() {
      if (this.shipmentDetail) {
        this.loadStatus = this.shipmentDetail.loadStatus
        if (this.shipmentDetail.loadStops) {
          this.loadStopData = []
          const stops = this.shipmentDetail.loadStops
          for (let i = 0; i < stops.length; i++) {
            this.loadStopData.push({
              text: stops[i].locationName,
              value: stops[i].id,
            })
          }
        }
        this.shipmentDetailCopy = cloneDeep(this.shipmentDetail)
      }
    },

    trackingUpdate(value) {
      this.pickupApptAt = value.pickupApptAt
      this.deliveryApptAt = value.deliveryApptAt
      this.arrivedAtShipperAt = value.arrivedAtShipperAt
      this.arrivedAtConsigneeAt = value.arrivedAtConsigneeAt
      this.pickedAt = value.pickedAt
      this.deliveredAt = value.deliveredAt
    },
  },

  methods: {
    onDateTimeChange(datetime, field) {
      if (datetime) {
        this.trackingUpdate[field] = datetime
      }
    },

    async submitLoad() {
      if (
        !this.$refs["load-tracking-form"].validate() ||
        this.isArrivedAtShipperRequired ||
        this.isPickedAtRequired ||
        this.isArrivedAtConsigneeRequired
      ) {
        return
      }

      const payload = {
        loadId: this.loadTrackingDialogId,
        trackingPayload: {
          load_status: {
            load_status: this.loadStatus,
          },
          tracking: {
            picked_at: this.formatDateForBackend(this.trackingUpdate.pickedAt),
            delivered_at: this.formatDateForBackend(
              this.trackingUpdate.deliveredAt
            ),
            pickup_appt_at: this.formatDateForBackend(
              this.trackingUpdate.pickupApptAt
            ),
            delivery_appt_at: this.formatDateForBackend(
              this.trackingUpdate.deliveryApptAt
            ),
            arrived_at_shipper_at: this.formatDateForBackend(
              this.trackingUpdate.arrivedAtShipperAt
            ),
            arrived_at_consignee_at: this.formatDateForBackend(
              this.trackingUpdate.arrivedAtConsigneeAt
            ),
            on_time_pick: this.trackingUpdate.onTimePick === true,
            on_time_delivery: this.trackingUpdate.onTimeDelivery === true,
          },
          tracking_update: {
            load_status: this.loadStatus,
            event_type: this.ediEventType,
            reason_code: this.ediReasonCode,
            city: this.city,
            state_province_region: this.state,
            postal_code: "",
            country: "USA",
            stop_id: this.loadStopId,
          },
        },
      }
      await this.$store.dispatch("loadDetail/POST_TRACKING_UPDATE", payload)
      this.ediEventType = null
      this.ediReasonCode = null
      this.city = null
      this.state = null
      try {
        await this.$store.dispatch(
          "loadDetail/GET_LOAD_DETAIL",
          this.loadTrackingDialogId
        )
        this.getNotes()
        this.clearDataAfterSave()
        this.closeDialog()
      } catch (error) {
        this.$store.commit("main/SET_SNACKBAR", {
          status: true,
          text: "Tracking update unsuccessful.",
          type: common.data.snackbarType.error,
        })
      }
    },

    clearDataAfterSave() {
      this.$store.commit("loadDetail/setLoadingIcon", false)
      this.shipmentDetailCopy = null
      this.loadStatus = null
      this.$store.commit("main/SET_SNACKBAR", {
        status: true,
        text: "Tracking update successful.",
        type: common.data.snackbarType.success,
      })
    },

    closeDialog() {
      this.$store.commit("loadDetail/toggleLoadTrackingDialog", false)
      this.$store.commit("loadDetail/setLoadTrackingDialogId", null)
      this.pickupApptAt = ""
      this.deliveryApptAt = ""
      this.arrivedAtShipperAt = ""
      this.arrivedAtConsigneeAt = ""
      this.pickedAt = ""
      this.deliveredAt = ""
    },

    loadReasonCode(value) {
      this.ediReasonCodeData = []
      if (value === EDI_EVENT_TYPES_IDS.PICKUP_APPOINTMENT) {
        this.pushReasonCode("AM")
        this.pushReasonCode("AJ")
        this.pushReasonCode("AO")
        this.pushReasonCode("AG")
      } else if (value === EDI_EVENT_TYPES_IDS.DELIVERY_APPOINTMENT) {
        this.pushReasonCode("AG")
        this.pushReasonCode("AJ")
        this.pushReasonCode("AM")
        this.pushReasonCode("AO")
        this.pushReasonCode("AV")
      } else if (value === EDI_EVENT_TYPES_IDS.ESTIMATED_DELIVERY) {
        this.pushReasonCode("AG")
        this.pushReasonCode("AJ")
        this.pushReasonCode("AM")
        this.pushReasonCode("AO")
        this.pushReasonCode("AV")
      } else if (value === EDI_EVENT_TYPES_IDS.ARRIVED_AT_SHIPPER) {
        this.pushReasonCode("AJ")
        this.pushReasonCode("AL")
        this.pushReasonCode("AO")
        this.pushReasonCode("AV")
      } else if (value === EDI_EVENT_TYPES_IDS.LOADED) {
        this.pushReasonCode("AM")
        this.pushReasonCode("AO")
      } else if (value === EDI_EVENT_TYPES_IDS.CHECK_CALL) {
        this.pushReasonCode("AJ")
        this.pushReasonCode("AO")
      } else if (value === EDI_EVENT_TYPES_IDS.ARRIVED_AT_CONSIGNEE) {
        this.pushReasonCode("AG")
        this.pushReasonCode("AJ")
        this.pushReasonCode("AM")
        this.pushReasonCode("AO")
        this.pushReasonCode("AV")
      } else if (value === EDI_EVENT_TYPES_IDS.DELIVERED) {
        this.pushReasonCode("AG")
        this.pushReasonCode("AO")
      }
    },

    pushReasonCode(code) {
      switch (code) {
        case "A1":
          this.ediReasonCodeData.push({
            text: "Missed Delivery",
            value: EDI_REASON_CODES_IDS.MISSED_DELIVERY,
          })
          break
        case "AF_ACCIDENT":
          this.ediReasonCodeData.push({
            text: "Accident",
            value: EDI_REASON_CODES_IDS.ACCIDENT,
          })
          break
        case "AG":
          this.ediReasonCodeData.push({
            text: "Consignee Related",
            value: EDI_REASON_CODES_IDS.CONSIGNEE_RELATED,
          })
          break
        case "AI":
          this.ediReasonCodeData.push({
            text: "Mechanical Breakdown",
            value: EDI_REASON_CODES_IDS.MECHANICAL_BREAKDOWN,
          })
          break
        case "AJ":
          this.ediReasonCodeData.push({
            text: "Other Carrier Related",
            value: EDI_REASON_CODES_IDS.OTHER_CARRIER_RELATED,
          })
          break
        case "AL":
          this.ediReasonCodeData.push({
            text: "Previous Stop",
            value: EDI_REASON_CODES_IDS.PREVIOUS_STOP,
          })
          break
        case "AM":
          this.ediReasonCodeData.push({
            text: "Shipper Related",
            value: EDI_REASON_CODES_IDS.SHIPPER_RELATED,
          })
          break
        case "AO":
          this.ediReasonCodeData.push({
            text: "Weather or Natural Disaster",
            value: EDI_REASON_CODES_IDS.WEATHER_OR_NATURAL_DISASTER,
          })
          break
        case "AY":
          this.ediReasonCodeData.push({
            text: "Missed Pickup",
            value: EDI_REASON_CODES_IDS.MISSED_PICKUP,
          })
          break
        case "BB":
          this.ediReasonCodeData.push({
            text: "Held Per Shipper",
            value: EDI_REASON_CODES_IDS.HELD_PER_SHIPPER,
          })
          break
        case "AV":
          this.ediReasonCodeData.push({
            text: "Exceeds Service Limitations",
            value: EDI_REASON_CODES_IDS.EXCEEDS_SERVICE_LIMITATIONS,
          })
      }
    },

    setLoadStatus({ loadStatus }) {
      this.loadStatus = loadStatus.loadStatus
    },

    getNotes() {
      this.$store.dispatch("loadDetail/GET_NOTES", {
        loadId: this.loadTrackingDialogId,
        limit: 10,
        offset: 0,
      })
    },

    formatDateForBackend(date) {
      if (date) {
        return moment(date, "MM-DD-YYYY HH:mm").format("YYYY-MM-DD HH:mm")
      }
      return null
    },
  },
}
</script>

<style>
.v-picker__title.primary {
  background-color: #0094e3 !important;
  border-color: #0094e3 !important;
}
</style>
