<!--
  accepts and emits: MM-DD-YYYY HH:mm
  shows: ISO 8601 date strings (YYYY-MM-DD) and HH:mm in separate fields
 -->
<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      {{ label }}
      <span v-if="required">(required)</span>
    </v-col>
    <!-- date -->
    <v-col cols="7" class="pt-0">
      <v-menu
        v-model="isDatePickerOpen"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            append-icon="mdi-calendar"
            readonly
            class="mt-0 pt-0"
            :disabled="disabled"
            :rules="dateValidationRules"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker v-model="date" @input="onDateInput"></v-date-picker>
      </v-menu>
    </v-col>
    <!-- / date -->
    <!-- time -->
    <v-col cols="5" class="pt-0">
      <v-text-field
        ref="timeField"
        v-model="time"
        v-mask="'##:##'"
        :disabled="disabled"
        :rules="timeValidationRules"
        append-icon="mdi-clock-outline"
        class="mt-0 pt-0"
        @input="onTimeInput"
      />
    </v-col>
    <!-- / time -->
  </v-row>
</template>

<script>
import moment from "moment"
import validation from "@/utils/form-validation"

export default {
  name: "DateTimePicker",
  props: {
    label: {
      type: String,
      default: "",
    },
    datetime: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDatePickerOpen: false,
      date: "",
      time: "",
    }
  },
  computed: {
    dateValidationRules() {
      const rules = []
      if (this.time || this.required) {
        rules.push(validation.rules.required)
      }
      return rules
    },
    timeValidationRules() {
      const rules = [validation.rules.time]
      if (this.date || this.required) {
        rules.push(validation.rules.required)
      }
      return rules
    },
  },
  watch: {
    datetime(value) {
      this.init(value)
    },
  },
  methods: {
    init(datetime) {
      if (datetime) {
        this.date = this.formatDateForPicker(datetime.split(" ")[0])
        this.time = datetime.split(" ")[1]
      } else {
        this.date = ""
        this.time = ""
      }
    },
    onDateInput(date) {
      this.isDatePickerOpen = false
      this.$refs.timeField.focus()
      if (date && this.time) {
        this.emitInputEvent()
      }
    },
    onTimeInput(time) {
      if (this.date && time) {
        this.emitInputEvent()
      }
    },
    emitInputEvent() {
      const date = this.formatDateForEvent(this.date)
      const datetime = `${date} ${this.time}`
      this.$emit("input", datetime)
    },

    formatDateForPicker(date) {
      return moment(date, "MM-DD-YYYY").format("YYYY-MM-DD")
    },

    formatDateForEvent(date) {
      return moment(date, "YYYY-MM-DD").format("MM-DD-YYYY")
    },
  },
}
</script>
