import Annotation from "./icons/Annotation"
import ArrowCircleRight from "./icons/ArrowCircleRight"
import ArrowLeft from "./icons/ArrowLeft"
import ArrowRight from "./icons/ArrowRight"
import Bell from "./icons/Bell"
import Book from "./icons/Book"
import Cash from "./icons/Cash"
import ChartSquareBar from "./icons/ChartSquareBar"
import ChevronDown from "./icons/ChevronDown"
import Cog from "./icons/Cog"
import Cube from "./icons/Cube"
import Eye from "./icons/Eye"
import EyeOff from "./icons/EyeOff"
import GlobeAlt from "./icons/GlobeAlt"
import Import from "./icons/Import"
import Loads from "./icons/Loads"
import MinusSm from "./icons/MinusSm"
import Pencil from "./icons/Pencil"
import PlusCircle from "./icons/PlusCircle"
import PlusSm from "./icons/PlusSm"
import Scale from "./icons/Scale"
import Template from "./icons/Template"
import Truck from "./icons/Truck"
import User from "./icons/User"
import X from "./icons/X"
import Dollar from "./icons/Dollar"

export default {
  Annotation,
  ArrowCircleRight,
  ArrowLeft,
  ArrowRight,
  Bell,
  Book,
  Cash,
  ChartSquareBar,
  ChevronDown,
  Cog,
  Cube,
  Eye,
  EyeOff,
  GlobeAlt,
  Import,
  Loads,
  MinusSm,
  Pencil,
  PlusCircle,
  PlusSm,
  Scale,
  Template,
  Truck,
  User,
  X,
  Dollar
}
