<template>
  <v-container data-cy="info-container">
    <v-row no-gutters>
      <v-col cols="5" lg="5" md="5" sm="12">
        <v-card-subtitle> Load #</v-card-subtitle>
        <v-card-title>
          {{ shipmentDetail.id }}
        </v-card-title>
      </v-col>
      <v-col cols="7" lg="7" md="7" sm="12">
        <v-card-subtitle> Customer</v-card-subtitle>
        <v-card-title>
          {{ shipmentDetail.customer.name }}
        </v-card-title>
      </v-col>
    </v-row>

    <v-container>
      <template v-if="mapType == mapTypes.googleMap">
        <GmapMap
          id="map"
          ref="mapRef"
          :center="{ lat: 37.77, lng: -122.447 }"
          :zoom="9"
          map-type-id="terrain"
          style="height: 350px; border-radius: 4px"
        />
      </template>
      <template v-else-if="mapType == mapTypes.hereMap">
        <here-map
          :center="{ lat: 37.77, lng: -122.447 }"
          :destination="destination"
          :origin="origin"
          :way-points="loadStops"
          height="350px"
          width="100%"
        />
      </template>
      <v-row class="pa-2 pt-8">
        <div class="col-md-5 pa-0 text-center">
          <p class="mb-1 grey--text text--darken-1">Pickup date</p>
          <v-card-text class="cargo-name pa-0 grey--text text--darken-4">
            <render-date :date="shipmentDetail.pickupDate" />
          </v-card-text>
        </div>
        <div class="col-md-2 pa-0 text-center">
          <v-icon class="pt-4"> mdi-arrow-right</v-icon>
        </div>
        <div class="col-md-5 pa-0 text-center">
          <p class="mb-1 grey--text text--darken-1">Delivery date</p>
          <v-card-text class="cargo-name pa-0 grey--text text--darken-4">
            <render-date :date="shipmentDetail.deliveryDate" />
          </v-card-text>
        </div>
      </v-row>
      <v-row class="pa-2">
        <div class="col-md-4 text-center">
          <p class="mb-1 grey--text text--darken-1">Equipment</p>
          <v-card-text class="text--regular pa-0 grey--text text--darken-4">
            {{ shipmentDetail.equipmentType }}
          </v-card-text>
        </div>
        <div class="col-md-4 text-center">
          <p class="mb-1 grey--text text--darken-1">Agent</p>
          <v-card-text
            :value="shipmentDetail.agent && shipmentDetail.agent.name"
            class="text--regular pa-0 grey--text text--darken-4"
          />
        </div>
        <div class="col-md-4 text-center">
          <p class="mb-1 grey--text text--darken-1">Order Type</p>
          <v-card-text
            :value="shipmentDetail.orderType && shipmentDetail.orderType.name"
            class="text--regular pa-0 grey--text text--darken-4"
          />
        </div>
        <div class="col-md-4 text-center">
          <p class="mb-1 grey--text text--darken-1">PO Number</p>
          <v-card-text class="text--regular pa-0 grey--text text--darken-4">
            <tooltip :tooltip-content="shipmentDetail.poNumber" />
          </v-card-text>
        </div>
        <div class="col-md-4 text-center">
          <p class="mb-1 grey--text text--darken-1">PU Number</p>
          <v-card-text class="text--regular pa-0 grey--text text--darken-4">
            <tooltip :tooltip-content="shipmentDetail.puNumber" />
          </v-card-text>
        </div>
        <div class="col-md-4 text-center">
          <p class="mb-1 grey--text text--darken-1">Ship. Pick. Num.</p>
          <v-card-text class="text--regular pa-0 grey--text text--darken-4">
            <tooltip :tooltip-content="shipmentDetail.shipperPickupNum" />
          </v-card-text>
        </div>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"
import { gmapApi } from "vue2-google-maps"
import Tooltip from "@/views/pages/components/Tooltip"
import RenderDate from "@/views/pages/components/RenderDate"
import HereMap from "@/views/pages/components/HereMap"
import common from "@/utils/common"

export default {
  name: "ShipmentInfo",
  components: {
    Tooltip,
    RenderDate,
    HereMap,
  },
  data() {
    return {
      map: null,
      markers: [],
      route: {
        origin: {
          lat: 37.77,
          lng: -122.447,
        },
        destination: {
          lat: 37.768,
          lng: -122.511,
        },
      },
      mapType: 0,
      origin: "",
      destination: "",
      loadStops: [],
      mapTypes: common.data.mapType,
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
      shipperInfo: "shipperInfo",
      consigneeInfo: "consigneeInfo",
    }),
    ...mapGetters("generic", {
      agentDetail: "agentDetail",
    }),
  },
  watch: {
    shipperInfo() {
      if (this.mapType === this.mapTypes.googleMap) {
        this.initMap()
      }
      if (this.mapType === this.mapTypes.hereMap) {
        this.origin =
          this.shipperInfo.latitude + "," + this.shipperInfo.longitude
      }
      return this.$store.state.shipperInfo
    },
    consigneeInfo() {
      if (this.mapType === this.mapTypes.hereMap) {
        this.destination =
          this.consigneeInfo.latitude + "," + this.consigneeInfo.longitude
      }
      return this.$store.state.consigneeInfo
    },
    shipmentDetail() {
      if (this.shipmentDetail.loadStops.length > 0) {
        this.loadStops = []
        const stops = this.shipmentDetail.loadStops
        if (this.mapType === this.mapTypes.hereMap) {
          for (let i = 0; i < stops.length; i++) {
            this.loadStops.push(
              stops[i].locationLatitude + "," + stops[i].locationLongitude
            )
          }
        } else {
          for (let i = 0; i < stops.length; i++) {
            this.loadStops.push({
              location:
                stops[i].locationLatitude + "," + stops[i].locationLongitude,
              stopover: true,
            })
          }
          this.initMap()
        }
      } else {
        this.loadStops = []
        if (this.mapType === this.mapTypes.googleMap) {
          this.initMap()
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch("generic/GET_AGENT_DETAIL").then((data) => {
      this.mapType = data.mapType
      if (this.mapType === this.mapTypes.googleMap) {
        this.initMap()
      }
    })
    if (this.mapType === this.mapTypes.googleMap) {
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.initMap()
      })
    }
  },
  methods: {
    initMap() {
      if (this.google) {
        const directionsRenderer = new this.google.maps.DirectionsRenderer()
        const directionsService = new this.google.maps.DirectionsService()
        const mapNew = new this.google.maps.Map(
          document.getElementById("map"),
          {
            zoom: 14,
            center: {
              lat: 37.77,
              lng: -122.447,
            },
          }
        )
        directionsRenderer.setMap(mapNew)
        this.calculateAndDisplayRoute(directionsService, directionsRenderer)
      }
    },
    calculateAndDisplayRoute(directionsService, directionsRenderer) {
      const waypoints = this.loadStops
      directionsService.route(
        {
          origin: {
            lat: this.shipperInfo.latitude,
            lng: this.shipperInfo.longitude,
          },
          destination: {
            lat: this.consigneeInfo.latitude,
            lng: this.consigneeInfo.longitude,
          },
          travelMode: "DRIVING",
          waypoints: waypoints,
        },
        (response, status) => {
          if (status === "OK") {
            directionsRenderer.setDirections(response)
          } else {
            window.alert("Directions request failed due to " + status)
          }
        }
      )
    },
  },
}
</script>
