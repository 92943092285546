<template>
  <v-container fluid class="pa-0 pt-3">
    <v-form ref="commodity-form" v-model="commodityFormValid" lazy-validation>
      <v-row>
        <v-col cols="10" class="pt-0 pb-0">
          <v-list-item-subtitle
            class="blue--text text--lighten-2 pa-1 pl-3 white--text box-header"
          >
            <span v-if="$vuetify.breakpoint.lgAndUp"
              >Commodity and Dimensions</span
            >
            <span v-else>Comm & Dims</span>
            <v-btn
              text
              small
              color="grey"
              @click="saveCommodityAndDimensionsUpdate()"
            >
              Save
            </v-btn>
          </v-list-item-subtitle>
        </v-col>
      </v-row>
      <v-container class="hgt320 pt-3">
        <v-card-text class="grey--text text--darken-4 pl-0 pt-0 pb-0">
          Dimensions
        </v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="3" class="pb-0">
            <v-text-field
              v-model="commodityInfo.loadLength"
              class="pb-0"
              label="Length"
              :rules="[rules.floatDigit, rules.required]"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="3" class="pb-0">
            <v-text-field
              v-model="commodityInfo.loadHeight"
              class="pb-0"
              label="Height"
              :rules="[rules.floatDigit]"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="3" class="pb-0">
            <v-text-field
              v-model="commodityInfo.loadWidth"
              class="pb-0"
              label="Width"
              :rules="[rules.floatDigit]"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="3" class="pb-0">
            <v-text-field
              v-model="commodityInfo.loadWeight"
              class="pb-0"
              label="Weight"
              :rules="[rules.alphaNumericWithComma, rules.required]"
            />
          </v-col>
        </v-row>
        <v-card-text class="grey--text text--darken-4 pl-0 py-0 pt-3">
          Commodity
        </v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="6" class="pb-0">
            <v-textarea
              v-model="commodityInfo.description"
              clearable
              no-resize
              class="pb-0"
              label="Description"
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="6" class="pb-0">
            <v-textarea
              v-model="commodityInfo.commodity"
              class="pb-0"
              clearable
              no-resize
              label="Commodity"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="3" class="pt-0">
            <v-text-field
              v-model="commodityInfo.miles"
              class="pb-0"
              label="Miles"
              :rules="[rules.floatDigit]"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="3" class="pt-0">
            <v-text-field
              v-model="commodityInfo.quantity"
              class="pb-0"
              label="Quantity"
              :rules="[rules.floatDigit]"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="3" class="pt-0">
            <v-text-field
              v-model="commodityInfo.temperature"
              class="pb-0"
              label="Temperature"
              :rules="[rules.floatDigit]"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="3" class="pt-0">
            <v-text-field
              v-model="commodityInfo.declaredValue"
              class="pb-0"
              label="Declared value"
              :rules="[rules.floatDigit]"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
export default {
  name: "CommodityDimensionsEdit",
  data() {
    return {
      commodityFormValid: false,
      rules: validation.rules,
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      commodityInfo: "commodityInfo",
      shipmentDetail: "shipmentDetail",
    }),
  },
  methods: {
    saveCommodityAndDimensionsUpdate() {
      if (this.$refs["commodity-form"].validate()) {
        const commodityPayload = {
          id: this.shipmentDetail.id,
          miles: this.commodityInfo.miles === "" ? 0 : this.commodityInfo.miles,
          load_length: this.commodityInfo.loadLength,
          load_width: this.commodityInfo.loadWidth,
          load_height: this.commodityInfo.loadHeight,
          load_weight: this.commodityInfo.loadWeight,
          description: this.commodityInfo.description,
          commodity: this.commodityInfo.commodity,
          pieces:
            this.commodityInfo.quantity === ""
              ? 0
              : this.commodityInfo.quantity,
          temperature: this.commodityInfo.temperature,
          declared_value: this.commodityInfo.declaredValue,
        }
        this.$store
          .dispatch("loadDetail/PATCH_LOAD_DETAIL", commodityPayload)
          .then(() => {
            this.$store.commit(
              "loadDetail/setCommodityAndDimensionsInfoEditing",
              false
            )
          })
          .catch(() => {
            // Swallow to avoid Sentry throwing an error
          })
      }
    },
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}
.hgt320 {
  height: 162px;
  overflow-y: auto;
}
</style>
