import { render, staticRenderFns } from "./AllLoads.vue?vue&type=template&id=135e91ec&scoped=true&"
import script from "./AllLoads.vue?vue&type=script&lang=js&"
export * from "./AllLoads.vue?vue&type=script&lang=js&"
import style0 from "./AllLoads.vue?vue&type=style&index=0&id=135e91ec&prod&scoped=true&lang=css&"
import style1 from "./AllLoads.vue?vue&type=style&index=1&id=135e91ec&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "135e91ec",
  null
  
)

export default component.exports