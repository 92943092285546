<template>
  <div class="backend-agent-autocomplete">
    <v-autocomplete
      v-model="agent"
      :items="agentList"
      :loading="loading"
      :search-input.sync="search"
      :color="color"
      item-text="name"
      item-value="id"
      :label="agentText"
      return-object
      close-on-content-click
      @keydown.enter="fireEnterEvent()"
      @blur="pushAgent()"
      @click:clear="removeAgent()"
    />
  </div>
</template>

<script>
import debounce from "lodash/debounce"
import api from "@/api/api"
import { mapGetters } from "vuex"

export default {
  name: "AgentAutoComplete",
  props: {
    agentText: {
      type: String,
      default: "Agent",
    },
    color: {
      type: String,
      default: "info",
    },
    defaultAgent: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectValue: null,
      loading: false,
      search: "",
      agents: [],
      isEnterEvent: false,
      selectedAgent: null,
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
    }),
    agentList() {
      return this.agents.map((agentUser) => {
        const name = agentUser.text
        return Object.assign({}, agentUser, { name })
      })
    },
    agent: {
      get() {
        return this.selectValue ? this.selectValue : null
      },
      set(newValue) {
        this.selectValue = newValue
        this.selectedAgent = newValue
        if (this.isEnterEvent === true) {
          this.fireEnterEvent()
        }
      },
    },
  },
  watch: {
    defaultAgent: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.agent = {
          id: value.id,
          name: value.name,
          text: value.text || value.name,
        }
        this.agents = [
          {
            id: value.id,
            name: value.name,
            text: value.text || value.name,
          },
        ]
      },
    },
    search(val) {
      if (!val || val.length === 0) {
        this.agents = []
        return
      }
      if (this.agent) {
        if (val !== this.agent.name) {
          if (this.loading) return
          this.loading = true
          this.getSearch()
        }
      } else {
        if (this.loading) return
        this.loading = true
        this.getSearch()
      }
    },
  },
  methods: {
    getSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/agent-front-end-autocomplete/`,
          {
            params: {
              q: this.search,
            },
          }
        )
        .then((res) => {
          this.agents = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Failed to retrieve agents.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    }, 500),
    pushAgent() {
      if (this.selectValue) {
        this.$store.commit("loadDetail/setAgent", this.selectValue)
        this.$store.commit("generic/setAgentId", this.selectValue.id)
        this.$emit("event", { agent: this.selectValue, flag: 2 })
      }
    },
    removeAgent() {
      this.agent = null
      this.$store.commit("loadDetail/setAgent", this.agent)
      this.$store.commit("generic/setAgentId", 0)
    },
    fireEnterEvent() {
      this.isEnterEvent = true
      if (
        this.selectedAgent &&
        this.selectedAgent.id !== undefined &&
        this.isEnterEvent === true
      ) {
        this.$emit("event", { agent: this.selectedAgent, flag: 1 })
        this.isEnterEvent = false
        this.selectedAgent = null
      }
    },
  },
}
</script>
