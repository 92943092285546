<template>
  <div v-if="!shipperDetailEdit" class="pt-3" data-cy="shipper-info">
    <v-row>
      <v-col
        :sm="$vuetify.breakpoint.mdAndUp ? '7' : '8'"
        class="pt-0 pb-0 pr-0"
        cols="12"
      >
        <v-list-item-subtitle
          class="
            blue--text
            text--lighten-2
            pa-1
            pl-3
            pb-4
            white--text
            box-header
            d-flex
            align-center
          "
        >
          Shipper info
          <v-btn
            v-if="!loadEditingDisabled"
            color="grey"
            small
            text
            @click="shipperInfoEditing()"
          >
            Edit
          </v-btn>
        </v-list-item-subtitle>
      </v-col>
    </v-row>
    <div
      :style="
        $vuetify.breakpoint.mdAndUp && showEdit
          ? 'height: 150px; overflow-y: auto;'
          : ''
      "
    >
      <v-container class="pt-0">
        <v-card-text v-if="shipper.name" class="text--regular pa-0 pt-0">
          <router-link
            :to="{
              name: 'Location Detail',
              params: { id: shipper.id },
            }"
          >
            {{ shipper.name }}
          </router-link>
        </v-card-text>
        <v-card-text v-else class="text--regular pa-0 pt-0">
          Assign Shipper
        </v-card-text>
        <v-list-item-subtitle class="grey--text text--darken-3 py-2">
          <span v-if="shipper.address">{{ shipper.address }}</span>
          <span v-if="shipper.city"> {{ shipper.city }}</span>
          <span v-if="shipper.stateProvinceRegion"
            >, {{ shipper.stateProvinceRegion }}</span
          >
          <span v-if="shipper.zipcode"> {{ shipper.zipcode }}</span>
        </v-list-item-subtitle>
      </v-container>
      <v-container class="pt-0">
        <v-row>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Contact Phone</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="shipper.contactPhone" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Pickup Early</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadInfo.shipperEarlyTime" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Pickup Late</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadInfo.shipperLateTime" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Location Hours</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="shipper.hours" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Pickup Number</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="loadInfo.shipperPickupNumber" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="xs pt-0 pb-0" cols="12">
            <p class="mb-1 grey--text text--darken-1">Notes</p>
            <v-list-item-subtitle
              v-if="loadInfo.shipperNotes"
              class="grey--text text--darken-4"
            >
              <!--<tooltip :tooltip-content="loadInfo.shipperNotes" />-->
              <template
                v-if="
                  $vuetify.breakpoint.lgAndUp
                    ? loadInfo.shipperNotes.length < 15
                    : loadInfo.shipperNotes.length < 5
                "
              >
                {{ loadInfo.shipperNotes }}
              </template>
              <template v-else>
                <v-dialog v-model="dialog" max-width="300px" scrollable>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="black"
                      dark
                      plain
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ loadInfo.shipperNotes.substring(0, 40) }}...
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title> Shipper Notes</v-card-title>
                    <v-divider />
                    <v-card-text
                      style="height: 300px"
                      v-html="
                        loadInfo.shipperNotes.replace(/(?:\r\n|\r|\n)/g, '<br>')
                      "
                    />
                  </v-card>
                </v-dialog>
              </template>
            </v-list-item-subtitle>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
  <shipper-info-edit v-else />
</template>
<script>
import Tooltip from "@/views/pages/components/Tooltip"
import ShipperInfoEdit from "@/views/pages/load-detail-components/edit-components/ShipperInfoEdit"
import common from "@/utils/common"
import { mapGetters } from "vuex"

export default {
  name: "ShipperInfo",
  components: {
    Tooltip,
    ShipperInfoEdit,
  },
  props: {
    hgtClass: {
      type: String,
      default: "hgt150",
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    menu: false,
    dialog: false,
  }),
  computed: {
    ...mapGetters("loadDetail", {
      shipperInfo: "shipperInfo",
      loadInfo: "loadInfo",
      shipperDetailEdit: "shipperDetailEdit",
      shipmentDetail: "shipmentDetail",
    }),
    ...mapGetters("generic", {
      agentDetail: "agentDetail",
    }),
    shipper() {
      return this.$store.state.loadDetail.shipperInfo
    },
    loadEditingDisabled() {
      return common.methods.disableButtonBaseOnLoadStatus(
        this.shipmentDetail,
        this.agentDetail
      )
    },
  },
  methods: {
    shipperInfoEditing() {
      this.$store.commit("loadDetail/setShipperDetailEditing", true)
    },
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}

.v-application a {
  color: #000;
  text-decoration: none;
}

.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
  padding: 0px !important;
  font-weight: bold;
  height: auto;
  text-transform: none;
}
</style>
