<template>
  <v-container
    v-if="!commodityAndDimensionsInfoEdit"
    class="pt-0"
    data-cy="comm-dims"
    fluid
  >
    <v-row>
      <v-col class="px-3 py-0" cols="10">
        <v-list-item-subtitle
          class="
            blue--text
            text--lighten-2
            pa-1
            py-4
            pl-0
            white--text
            box-header
          "
        >
          <span v-if="$vuetify.breakpoint.lgAndUp"
            >Commodity and Dimensions</span
          >
          <span v-else>Comm & Dims</span>
          <v-btn
            v-if="!loadEditingDisabled"
            color="grey"
            small
            text
            @click="commodityAndDimensionsInfoEditing()"
          >
            Edit
          </v-btn>
        </v-list-item-subtitle>
      </v-col>
    </v-row>
    <v-row class="px-3">
      <v-container
        :class="$vuetify.breakpoint.mdAndUp ? 'pa-0 hgt320' : 'pa-0'"
      >
        <v-card-text class="grey--text text--darken-4 pl-0 pt-0">
          Dimensions
        </v-card-text>
        <v-row no-gutters>
          <v-col class="pt-0" cols="12" lg="3" md="3" sm="3">
            <p class="mb-1 grey--text text--darken-1">Length</p>
            <p class="grey--text text--darken-4 mb-1">
              {{ commodityInfo.loadLength }}
            </p>
          </v-col>
          <v-col class="pt-0" cols="12" lg="3" md="3" sm="3">
            <p class="mb-1 grey--text text--darken-1">Height</p>
            <p class="grey--text text--darken-4 mb-1">
              {{ commodityInfo.loadHeight }}
            </p>
          </v-col>
          <v-col class="pt-0" cols="12" lg="3" md="3" sm="3">
            <p class="mb-1 grey--text text--darken-1">Width</p>
            <p class="grey--text text--darken-4 mb-1">
              {{ commodityInfo.loadWidth }}
            </p>
          </v-col>
          <v-col class="pt-0" cols="12" lg="3" md="3" sm="3">
            <p class="mb-1 grey--text text--darken-1">Weight</p>
            <p class="grey--text text--darken-4 mb-1">
              {{ commodityInfo.loadWeight }}
            </p>
          </v-col>
        </v-row>
        <v-card-text class="grey--text text--darken-4 pl-0 py-1">
          Commodity
        </v-card-text>
        <v-row />

        <v-row class="mt-6">
          <v-col class="pt-0" cols="12" lg="6" md="12" sm="12">
            <p class="mb-1 grey--text text--darken-1">Description</p>
            <p class="grey--text text--darken-4 mb-1">
              <tooltip :tooltip-content="commodityInfo.description" />
            </p>
          </v-col>
          <v-col class="pt-0" cols="12" lg="6" md="12" sm="12">
            <p class="mb-1 grey--text text--darken-1">Commodity</p>
            <p class="grey--text text--darken-4 mb-1">
              <tooltip :tooltip-content="commodityInfo.commodity" />
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0" cols="12" lg="3" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Miles</p>
            <p class="grey--text text--darken-4">
              {{ commodityInfo.miles }}
            </p>
          </v-col>
          <v-col class="pt-0" cols="12" lg="3" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Quantity</p>
            <p class="grey--text text--darken-4">
              {{ commodityInfo.quantity }}
            </p>
          </v-col>
          <v-col class="pt-0" cols="12" lg="3" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Temp</p>
            <p class="grey--text text--darken-4">
              {{ commodityInfo.temperature }}
            </p>
          </v-col>
          <v-col class="pt-0" cols="12" lg="3" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Value</p>
            <p class="grey--text text--darken-4">
              {{ commodityInfo.declaredValue }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
  <commodity-dimensions-edit v-else />
</template>
<script>
import { mapGetters } from "vuex"
import Tooltip from "@/views/pages/components/Tooltip"
import CommodityDimensionsEdit from "@/views/pages/load-detail-components/edit-components/CommodityAndDimensionsEdit"
import common from "@/utils/common"

export default {
  name: "CommodityDimensions",
  components: {
    CommodityDimensionsEdit,
    Tooltip,
  },
  computed: {
    ...mapGetters("loadDetail", {
      commodityInfo: "commodityInfo",
      commodityAndDimensionsInfoEdit: "commodityAndDimensionsInfoEdit",
      shipmentDetail: "shipmentDetail",
    }),
    ...mapGetters("generic", {
      agentDetail: "agentDetail",
    }),
    loadEditingDisabled() {
      return common.methods.disableButtonBaseOnLoadStatus(
        this.shipmentDetail,
        this.agentDetail
      )
    },
  },
  methods: {
    commodityAndDimensionsInfoEditing() {
      this.$store.commit(
        "loadDetail/setCommodityAndDimensionsInfoEditing",
        true
      )
    },
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}

.hgt320 {
  height: 150px;
  overflow: hidden auto;
}

.row + .row {
  margin-top: 0;
}
</style>
