<template>
  <div>
    <v-row>
      <v-col cols="12" class="pt-3 pb-0 pr-0" sm="7" md="9">
        <v-list-item-subtitle
          class="blue--text text--lighten-2 pa-1 pl-3 white--text box-header"
        >
          Consignee info
          <v-btn text small color="grey" @click="saveConsigneeUpdate()">
            Save
          </v-btn>
        </v-list-item-subtitle>
      </v-col>
    </v-row>
    <div class="px-4 pt-6" style="height: 162px; overflow-y: auto">
      <v-form
        ref="consigneeInfoEdit-form"
        v-model="consigneeInfoFormValid"
        lazy-validation
      >
        <v-row>
          <v-col cols="12" class="pb-0 pt-1 pb-0">
            <location-auto-complete
              direction="dest"
              form-type="3"
              :model="consigneeInfo"
              stop-sequence="2"
              :customer-id="shipmentDetail.customer.id"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4" class="pt-0 pb-0">
            <v-text-field
              v-if="consigneeInfo.id"
              v-model="consigneeInfo.contactPhone"
              class="pt-0"
              label="Contact Phone"
              :rules="[rules.phone]"
              @keydown.enter.native="saveConsigneeUpdate()"
            />
            <v-text-field v-else class="pt-0" disabled label="Contact Phone" />
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4" class="pt-0 pb-0">
            <v-text-field
              v-if="loadInfo"
              v-model="loadInfo.consigneeEarlyTime"
              v-mask="'##:##'"
              class="pt-0"
              label="Delivery Early"
              :rules="[rules.time]"
              @keydown.enter.native="saveConsigneeUpdate()"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4" class="pt-0 pb-0">
            <v-text-field
              v-if="loadInfo"
              v-model="loadInfo.consigneeLateTime"
              v-mask="'##:##'"
              class="pt-0"
              label="Delivery Late"
              :rules="[rules.time]"
              @keydown.enter.native="saveConsigneeUpdate()"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4" class="pt-0 pb-0">
            <v-text-field
              v-if="consigneeInfo.id"
              v-model="consigneeInfo.hours"
              class="pt-0"
              label="Location Hours"
              @keydown.enter.native="saveConsigneeUpdate()"
            />
            <v-text-field v-else class="pt-0" disabled label="Location Hours" />
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4" class="pt-0 pb-0">
            <v-text-field
              v-model="loadInfo.deliveryNumber"
              label="Delivery Number"
              class="pt-0"
              :rules="[rules.alphaNumeric]"
              @keydown.enter.native="saveConsigneeUpdate()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-textarea
              v-model="loadInfo.consigneeNotes"
              class="pt-0"
              label="Notes"
              auto-grow
              rows="4"
              row-height="30"
              @keydown.enter.native="saveConsigneeUpdate()"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import LocationAutoComplete from "@/views/pages/components/LocationAutoComplete"
import validation from "@/utils/form-validation"
export default {
  name: "ConsigneeInfoEdit",
  components: {
    LocationAutoComplete,
  },
  data() {
    return {
      valid: false,
      consignee: null,
      earlyMenu: false,
      lateMenu: false,
      consigneeInfoFormValid: false,
      rules: validation.rules,
    }
  },
  computed: {
    ...mapGetters(
      "loadDetail",
      {
        shipmentDetail: "shipmentDetail",
        consigneeInfo: "consigneeInfo",
        loadInfo: "loadInfo",
      },
      "location",
      {
        currentLocation: "currentLocation",
      }
    ),
  },
  mounted() {
    this.consignee = this.consigneeInfo
  },
  methods: {
    saveConsigneeUpdate() {
      if (this.$refs["consigneeInfoEdit-form"].validate()) {
        let payload = {}
        if (this.consigneeInfo.id) {
          payload = {
            id: this.shipmentDetail.id,
            consignee: {
              id: this.consigneeInfo.id,
              contact_phone: this.consigneeInfo.contactPhone,
              city: this.consigneeInfo.city,
              hours: this.consigneeInfo.hours,
            },
            delivery_early_time: this.loadInfo.consigneeEarlyTime,
            delivery_late_time: this.loadInfo.consigneeLateTime,
            consignee_delivery_number: this.loadInfo.deliveryNumber,
            consignee_delivery_notes: this.loadInfo.consigneeNotes,
          }
        } else {
          payload = {
            id: this.shipmentDetail.id,
            consignee: null,
            delivery_early_time: this.loadInfo.consigneeEarlyTime,
            delivery_late_time: this.loadInfo.consigneeLateTime,
            consignee_delivery_number: this.loadInfo.deliveryNumber,
            consignee_delivery_notes: this.loadInfo.consigneeNotes,
          }
        }

        this.$store
          .dispatch("loadDetail/PATCH_LOAD_DETAIL", payload)
          .then(() => {
            this.$store.commit("loadDetail/setConsigneeDetailEditing", false)
            if (this.consignee.id !== payload.consignee.id) {
              this.$store.commit("loadDetail/setReloadMap", true)
            }
          })
          .catch(() => {
            // Swallow to avoid Sentry throwing an error
          })
      }
    },
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}
</style>
