<template>
  <v-autocomplete
    v-model="defaultType"
    :items="types"
    item-text="name"
    item-value="id"
    :loading="loading"
    :rules="rules"
    class="mt-n2"
    color="#e1b753"
    label="Accessorial Type"
    @change="fireEnterEvent"
  >
    <template v-slot:item="{ item }">
      <div class="py-2">
        <div class="text-uppercase font-weight-medium">{{ item.name }}</div>
        <div>{{ item.description }}</div>
        <div>Reimbursement: {{ item.reimbursement }}</div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import api from "@/api/api"

export default {
  name: "AccessorialTypeAutoComplete",
  props: {
    defaultType: {
      type: Number,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectValue: null,
      loading: false,
      types: [],
      isEnterEvent: false,
      selectedType: null,
    }
  },
  mounted() {
    this.getAccessorialTypes()
  },
  methods: {
    getAccessorialTypes() {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-accessorial-types/`,
          {}
        )
        .then((res) => {
          this.types = res.data
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Failed to retrieve types.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    },

    fireEnterEvent(typeId) {
      this.$emit("event", typeId)
    },
  },
}
</script>
