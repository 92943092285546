<template>
  <button
    type="button"
    class="button"
    :class="classes"
    :disabled="disabled"
    @click="onClick"
  >
    <icon
      v-if="iconLeft"
      :name="iconLeft"
      :color="iconColor"
      :class="{ 'mr-4': $slots.default }"
      class="iconLeft"
      size="20"
    />
    <slot></slot>
    <icon
      v-if="iconRight"
      :name="iconRight"
      :color="iconColor"
      :class="{ 'ml-4': $slots.default }"
      class="iconRight"
      size="20"
    />
  </button>
</template>

<script>
import Icon from "@/views/components/Icon"
import colors from "@/assets/colors.json"

export default {
  name: "Button",
  components: {
    Icon,
  },
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "default", // small, large
    },
    theme: {
      type: String,
      default: "secondary", // primary, attention, dropdown
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconRight: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        themePrimary: this.primary || this.theme === "primary",
        themeSecondary: this.theme === "secondary" && !this.primary,
        themeAttention: this.theme === "attention" && !this.primary,
        themeDropdown: this.theme === "dropdown" && !this.primary,
        sizeLarge: this.size === "large",
        sizeDefault: this.size === "default",
        sizeSmall: this.size === "small",
        disabled: this.disabled,
      }
    },
    iconColor() {
      if (this.disabled) {
        return colors["--neutral-4"]
      }
      if (this.theme === "primary" || this.primary) {
        return colors["--white"]
      }
      if (this.theme === "secondary" && !this.primary) {
        return colors["--dark-blue"]
      }
      if (this.theme === "attention" && !this.primary) {
        return colors["--dark-blue"]
      }
      if (this.theme === "dropdown" && !this.primary) {
        return colors["--dark-blue"]
      }
      return null
    },
  },
  methods: {
    onClick() {
      this.$emit("click")
    },
  },
}
</script>

<style scoped>
@import "../../assets/colors.css";
@import "../../assets/helpers.css";

.button {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 8px;
  transition: all 200ms linear;
}

.iconLeft,
.iconRight {
  flex-shrink: 0;
}

/* themes */

.themePrimary {
  color: var(--white);
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
}

.themePrimary:hover {
  background-color: var(--neutral-blue);
}

.themeSecondary {
  color: var(--dark-blue);
  background-color: var(--white);
  border: 1px solid var(--dark-blue);
}

.themeSecondary:hover {
  background-color: var(--light-blue);
}

.themeAttention {
  background-color: var(--yellow);
  border: 1px solid var(--yellow);
}

.themeAttention:hover {
  background-color: var(--orange);
  border: 1px solid var(--orange);
}

.themeDropdown {
  color: var(--dark-blue);
  background-color: var(--white);
  border: none;
}

.themeDropdown:hover {
  background-color: var(--light-blue);
}

/* sizes */

.sizeSmall {
  padding: 9px;
}

.sizeDefault {
  padding: 12px;
}

.sizeLarge {
  padding: 16px;
}

/* disabled */

.disabled {
  color: var(--neutral-4);
  background-color: var(--neutral-1);
  border: 1px solid var(--neutral-2);
  pointer-events: none;
}
</style>
