<template>
  <component :is="name" :width="size" :heigth="size" :color="color" />
</template>

<script>
import colors from "@/assets/colors.json"
import icons from "./icons"

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
    },
  },
  components: {
    ...icons,
  },
}
</script>
