<template>
  <div
    :height="$vuetify.breakpoint.mdAndUp ? '385px' : ''"
    class="mt-0"
    data-cy="tender-details"
  >
    <div v-if="!tenderDetailEditing" class="pt-0">
      <v-tabs v-model="tab" fixed-tabs show-arrows>
        <v-tab href="#tender" @click="getTenderDetails">
          Tender
          <v-btn
            v-if="!loadEditingDisabled"
            color="grey"
            small
            text
            @click="tenderDetailEdit()"
          >
            Edit
          </v-btn>
        </v-tab>
        <v-tab href="#accessorials"> Accessorials</v-tab>
        <v-tab href="#truckstopprice"> Rates</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="tender" value="tender">
          <v-container class="pt-2 pb-0" style="height: 315px">
            <v-row>
              <v-col cols="6">
                <v-responsive
                  class="
                    text-center
                    blue
                    lighten-2
                    rounded-circle
                    d-inline-flex
                    align-center
                    justify-center
                  "
                  height="11"
                  width="11"
                />
                <div
                  :style="
                    $vuetify.breakpoint.lgAndUp
                      ? 'border-left:1px solid #64B5F6; height:220px; margin-left:5px;'
                      : 'border-left:1px solid #64B5F6; height:230px; margin-left:5px; font-size: 10px'
                  "
                >
                  <div class="ml-2">
                    <p class="mb-1 grey--text text--darken-1">Customer Base</p>
                    <v-card-text
                      class="text--regular pa-0 grey--text text--darken-4"
                    >
                      ${{ tenderDetails.customerBase }}
                    </v-card-text>
                  </div>
                  <div class="mt-2 ml-2">
                    <p class="mb-1 grey--text text--darken-1">
                      Total Accessorial
                    </p>
                    <v-card-text
                      class="text--regular pa-0 grey--text text--darken-4"
                    >
                      ${{ tenderDetails.customerTotalAccessorials }}
                    </v-card-text>
                  </div>
                  <div class="mt-4 ml-2">
                    <p class="mb-1 grey--text text--darken-1">Load Margin</p>
                    <v-card-text
                      class="text--regular pa-0 grey--text text--darken-4"
                    >
                      ${{ tenderDetails.loadMargin }}
                    </v-card-text>
                  </div>
                  <div class="mt-4 ml-2">
                    <p class="mb-1 grey--text text--darken-1">Percent Margin</p>
                    <v-card-text
                      class="text--regular pa-0 grey--text text--darken-4"
                    >
                      {{ tenderDetails.percentMargin }}%
                    </v-card-text>
                  </div>
                  <div class="tender-detail-bottom-box pa-1">
                    <p
                      v-if="$vuetify.breakpoint.lgAndUp"
                      class="mb-0 ml-1 grey--text text--darken-3 pa-1"
                    >
                      Customer Total
                    </p>
                    <p v-else class="mb-0 ml-1 grey--text text--darken-3 pa-1">
                      Cus. Total
                    </p>
                    <v-card-text
                      class="
                        text--regular
                        pa-1
                        ml-1
                        blue--text
                        text--lighten-1
                        font-weight-bold
                      "
                    >
                      ${{ tenderDetails.customerTotalPrice }}
                    </v-card-text>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <v-responsive
                  class="
                    text-center
                    blue
                    lighten-2
                    rounded-circle
                    d-inline-flex
                    align-center
                    justify-center
                  "
                  height="11"
                  width="11"
                />
                <div
                  :style="
                    $vuetify.breakpoint.lgAndUp
                      ? 'border-left:1px solid #64B5F6; height:220px; margin-left:5px;'
                      : 'border-left:1px solid #64B5F6; height:230px; margin-left:5px; font-size: 10px'
                  "
                >
                  <div class="ml-2">
                    <p class="mb-1 grey--text text--darken-1">
                      Carrier Start Buy
                    </p>
                    <v-card-text
                      class="text--regular pa-0 grey--text text--darken-4"
                    >
                      ${{ tenderDetails.carrierStartBuy }}
                    </v-card-text>
                  </div>
                  <div class="mt-2 ml-2">
                    <p class="mb-1 grey--text text--darken-1">
                      Carrier Max Buy
                    </p>
                    <v-card-text
                      class="text--regular pa-0 grey--text text--darken-4"
                    >
                      ${{ tenderDetails.carrierMaxBuy }}
                    </v-card-text>
                  </div>
                  <div class="mt-2 ml-2">
                    <p class="mb-1 grey--text text--darken-1">
                      Total Accessorial
                    </p>
                    <v-card-text
                      class="text--regular pa-0 grey--text text--darken-4"
                    >
                      ${{ tenderDetails.carrierTotalAccessorials }} (${{
                        tenderDetails.carrierTotalPrepaidAccessorials
                      }}
                      Prepaid)
                    </v-card-text>
                  </div>
                  <div class="mt-2 ml-2">
                    <p class="mb-1 grey--text text--darken-1">
                      Carrier Line Haul
                    </p>
                    <v-card-text
                      class="text--regular pa-0 grey--text text--darken-4"
                    >
                      ${{ tenderDetails.carrierLineHaul }}
                    </v-card-text>
                  </div>
                  <div class="tender-detail-bottom-box pa-1">
                    <p class="mb-0 ml-1 grey--text text--darken-3 pa-1">
                      Carrier Total
                    </p>
                    <v-card-text
                      class="
                        text--regular
                        pa-1
                        ml-1
                        blue--text
                        text--lighten-1
                        font-weight-bold
                      "
                    >
                      ${{ tenderDetails.carrierTotalPrice }}
                    </v-card-text>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item key="accessorials" value="accessorials">
          <v-expansion-panels
            v-if="!loadEditingDisabled"
            id="top-panel"
            v-model="panelIndex"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="blue--text text-h5">
                {{ panelText }}
              </v-expansion-panel-header>
              <v-expansion-panel-content style="height: 275px">
                <div>
                  <v-form ref="accessorial-form" v-model="valid">
                    <v-row class="mx-1">
                      <v-col class="mt-3 py-0" cols="6">
                        <accessorial-type-auto-complete
                          :default-type="accTypeId"
                          :rules="[rules.required]"
                          @event="setAccType"
                        />
                      </v-col>
                      <v-col class="py-0" cols="6">
                        <v-select
                          v-model="chargeTo"
                          :items="chargeToTypes"
                          :rules="[rules.required]"
                          clearable
                          item-text="name"
                          item-value="id"
                          label="Apply To"
                          @change="chargeToChange"
                        />
                      </v-col>
                      <v-col v-if="chargeTo === 2" class="py-0" cols="3">
                        <v-checkbox v-model="isPrepaid" label="Prepaid" />
                      </v-col>
                    </v-row>
                    <v-row class="mx-1">
                      <v-col class="py-0" cols="6">
                        <v-select
                          v-model="chargeType"
                          :items="chargeTypes"
                          :rules="[rules.required]"
                          clearable
                          item-text="name"
                          item-value="id"
                          label="Charge Type"
                        />
                      </v-col>
                      <v-col class="py-0" cols="6">
                        <v-text-field
                          v-model="quantity"
                          :rules="[rules.required, rules.floatDigit]"
                          label="Quantity"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="mx-1">
                      <v-col class="py-0" cols="6">
                        <v-text-field
                          v-model="amount"
                          :rules="[rules.required, rules.floatDigit]"
                          label="Amount"
                        />
                      </v-col>
                      <v-col class="py-0" cols="6">
                        <v-text-field
                          v-model="reason"
                          :rules="[rules.required]"
                          label="Reason"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row class="py-3">
                    <v-col cols="3" lg="3" md="4" sm="2">
                      <v-btn
                        class="
                          white--text
                          text--lighten-2
                          font-weight-bold
                          ml-3
                        "
                        color="error"
                        small
                        @click="resetPanel"
                      >
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col cols="3" lg="3" md="4" sm="2">
                      <v-btn
                        class="white--text text--lighten-2 font-weight-bold"
                        color="info"
                        small
                        @click="saveAccessorial"
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <template
            v-if="
              loadCustomerAccessorialList.length ||
              loadCarrierAccessorialList.length
            "
          >
            <v-card
              class="ma-0"
              flat
              height="280"
              style="overflow: hidden auto"
            >
              <v-row class="mt-3 justify-space-around">
                <v-col cols="5">
                  <v-list>
                    <v-list-item-title
                      class="mx-3 mb-2 text-center text-h4 font-weight-medium"
                    >
                      Customer
                    </v-list-item-title>
                    <v-list-item
                      v-for="(item, index) in loadCustomerAccessorialList"
                      :key="index"
                    >
                      <div class="my-1">
                        <div>
                          <span class="font-weight-medium">Acc Type:</span>
                          {{ item.accessorialTypeText }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Charge Type:</span>
                          {{ item.chargeTypeDisplay }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Quantity:</span>
                          {{ item.quantity }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Amount:</span>
                          {{ item.amount }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Total:</span>
                          {{ item.totalAmount.toFixed(2) }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Reason:</span>
                          {{ item.reason }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Prepaid:</span>
                          {{ item.prepaid === true ? "Yes" : "No" }}
                        </div>
                        <v-row>
                          <v-col class="pl-0" cols="6">
                            <v-btn
                              class="blue--text pa-0"
                              small
                              text
                              @click="openEditPanel(item)"
                            >
                              Edit
                            </v-btn>
                          </v-col>
                          <v-col class="pl-0" cols="6">
                            <v-btn
                              class="red--text pa-0"
                              small
                              text
                              @click="deleteAccessorial(item.id)"
                            >
                              Delete
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-divider vertical />
                <v-col cols="5">
                  <v-list>
                    <v-list-item-title
                      class="mx-3 mb-2 text-center text-h4 font-weight-medium"
                    >
                      Carrier
                    </v-list-item-title>
                    <v-list-item
                      v-for="(item, index) in loadCarrierAccessorialList"
                      :key="index"
                    >
                      <div class="my-1">
                        <div>
                          <span class="font-weight-medium">Acc Type:</span>
                          {{ item.accessorialTypeText }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Charge Type:</span>
                          {{ item.chargeTypeDisplay }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Quantity:</span>
                          {{ item.quantity }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Amount:</span>
                          {{ item.amount }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Total:</span>
                          {{ item.totalAmount.toFixed(2) }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Reason:</span>
                          {{ item.reason }}
                        </div>
                        <div>
                          <span class="font-weight-medium">Prepaid:</span>
                          {{ item.prepaid === true ? "Yes" : "No" }}
                        </div>
                        <v-row>
                          <v-col class="pl-0" cols="6">
                            <v-btn
                              class="blue--text pa-0"
                              small
                              text
                              @click="openEditPanel(item)"
                            >
                              Edit
                            </v-btn>
                          </v-col>
                          <v-col class="pl-0" cols="6">
                            <v-btn
                              class="red--text pa-0"
                              small
                              text
                              @click="deleteAccessorial(item.id)"
                            >
                              Delete
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <template v-else>
            <div class="text-center mt-4">No data</div>
          </template>
        </v-tab-item>
        <v-tab-item key="truckstopprice" value="truckstopprice">
          <truckstop-price />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div v-else class="pt-0">
      <tender-detail-edit />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
import TenderDetailEdit from "@/views/pages/load-detail-components/edit-components/TenderDetailEdit"
import TruckstopPrice from "@/views/pages/load-detail-components/TruckstopPrice"
import AccessorialTypeAutoComplete from "@/views/pages/load-detail-components/AccessorialTypeAutoComplete"
import common from "@/utils/common"

export default {
  name: "TenderDetail",
  components: {
    TenderDetailEdit,
    TruckstopPrice,
    AccessorialTypeAutoComplete,
  },
  props: {
    showEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tab: null,
      valid: false,
      panelIndex: null,
      panelText: "Add Load Accessorial",
      rules: validation.rules,
      reason: "",
      accTypeId: null,
      quantity: null,
      amount: null,
      chargeTo: null,
      chargeType: null,
      chargeTypes: [
        {
          id: 1,
          name: "Flat Rate",
        },
        {
          id: 2,
          name: "Per Day",
        },
        {
          id: 3,
          name: "Per Hour",
        },
        {
          id: 4,
          name: "Per Mile",
        },
      ],
      chargeToTypes: [
        {
          id: 1,
          name: "Customer",
        },
        {
          id: 2,
          name: "Carrier",
        },
      ],
      editIndex: null,
      id: null,
      isPrepaid: false,
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
      tenderDetails: "tenderDetails",
      tenderDetailEditing: "tenderDetailEditing",
      loadingIcon: "loadingIcon",
      loadCustomerAccessorialList: "loadCustomerAccessorialList",
      loadCarrierAccessorialList: "loadCarrierAccessorialList",
    }),
    ...mapGetters("generic", {
      agentDetail: "agentDetail",
    }),
    loadEditingDisabled() {
      return common.methods.disableButtonBaseOnLoadStatus(
        this.shipmentDetail,
        this.agentDetail
      )
    },
  },
  watch: {
    panelIndex() {
      if (this.panelIndex !== 0) {
        this.resetPanel()
      }
    },
    shipmentDetail(value) {
      this.id = value.id
      this.getAccessorialList()
    },
  },
  methods: {
    getTenderDetails() {
      this.$store.dispatch("loadDetail/GET_LOAD_DETAIL", this.id)
    },
    setAccType(typeId) {
      this.accTypeId = typeId
    },
    getAccessorialList() {
      this.$store.dispatch("loadDetail/GET_LOAD_ACCESSORIALS", this.id)
    },
    tenderDetailEdit() {
      this.$store.commit("loadDetail/setTenderDetailEditing", true)
    },
    saveAccessorial() {
      if (this.$refs["accessorial-form"].validate()) {
        const details = {
          accessorial_type: this.accTypeId,
          amount: this.amount,
          quantity: this.quantity,
          reason: this.reason,
          charge_type: this.chargeType,
          charge_to: this.chargeTo,
          prepaid: this.isPrepaid === true,
        }
        if (this.editIndex === null) {
          const payload = {
            loadId: this.id,
            details,
          }
          this.$store
            .dispatch("loadDetail/ADD_LOAD_ACCESSORIAL", payload)
            .then(() => {
              this.getAccessorialList()
            })
        } else {
          const payload = {
            id: this.editIndex,
            details,
          }
          this.$store
            .dispatch("loadDetail/UPDATE_LOAD_ACCESSORIAL", payload)
            .then(() => {
              this.getAccessorialList()
            })
        }
        this.resetPanel()
      }
    },
    deleteAccessorial(index) {
      this.$store
        .dispatch("loadDetail/DELETE_LOAD_ACCESSORIAL", index)
        .then(() => {
          this.getAccessorialList()
        })
    },
    resetPanel() {
      this.panelIndex = null
      this.panelText = "Add Load Accessorial"
      this.editIndex = null
      this.accTypeId = null
      this.$refs["accessorial-form"].reset()
    },
    openEditPanel(item) {
      this.editIndex = item.id
      this.panelIndex = 0
      this.panelText = "Edit Load Acessorial"
      this.quantity = item.quantity
      this.amount = item.amount
      this.reason = item.reason
      this.chargeTo = item.chargeTo
      this.chargeType = item.chargeType
      this.accTypeId = item.accessorialType
      this.isPrepaid = item.prepaid
    },
    chargeToChange(value) {
      if (value !== 2) {
        this.isPrepaid = false
      }
    },
  },
}
</script>

<style scoped>
.rounded-circle {
  border-radius: 50% !important;
}

.tender-detail-bottom-box {
  background-color: rgba(30, 136, 229, 0.1);
  border: 1px solid #1e88e5;
  border-radius: 0 4px 4px 4px;
  position: absolute;
  width: 40%;
  bottom: 10px;
  margin-left: -1px;
}

.box-header {
  font-size: 16px;
  font-weight: 500;
}
</style>
