<template>
  <v-container>
    <v-row class="align-center">
      <v-col class="pb-0" cols="3" lg="3" md="3" xl="3">
        <v-text-field v-model="margin" class="pt-0" label="Margin" />
      </v-col>
      <v-col class="pb-0" cols="6" lg="6" md="5" xl="6">
        <v-select
          v-model="radius"
          :items="radiusData"
          item-text="text"
          item-value="value"
          label="Radius"
        />
      </v-col>
      <v-col class="pb-0" cols="3" lg="3" md="3" xl="3">
        <v-btn color="green" x-small @click="fetchTruckStopPrice()">
          Fetch Rates
        </v-btn>
      </v-col>
    </v-row>
    <p class="pt-3">Pricing: shipper / carrier</p>
    <v-row
      :style="
        $vuetify.breakpoint.md
          ? 'height: 169px; overflow-y: auto;'
          : 'height: 205px'
      "
    >
      <v-col class="pt-0" cols="5">
        <div><h3>Paid</h3></div>
        <b class="float-left pr-1"> 30 Days: </b>
        <p class="mb-0">Reports: {{ priceRate.paid30dReports }}</p>
        <p v-if="priceRate.paid30dReports" class="mb-0">
          ${{ priceRate.paid30dShipperRate }} / ${{
            priceRate.paid30dTruckerRate
          }}
          ({{ priceRate.paid30dShipper }} / {{ priceRate.paid30dTrucker }})
        </p>
        <b class="float-left pr-1"> 90 Days: </b>
        <p class="mb-0">Reports: {{ priceRate.paid90dReports }}</p>
        <p v-if="priceRate.paid90dReports" class="mb-0">
          ${{ priceRate.paid90dShipperRate }} / ${{
            priceRate.paid90dTruckerRate
          }}
          ({{ priceRate.paid90dShipper }} / {{ priceRate.paid90dTrucker }})
        </p>
        <b class="float-left pr-1"> 365 Days: </b>
        <p class="mb-0">Reports: {{ priceRate.paid365dReports }}</p>
        <p v-if="priceRate.paid365dReports" class="mb-0">
          ${{ priceRate.paid365dShipperRate }} / ${{
            priceRate.paid365dTruckerRate
          }}
          ({{ priceRate.paid365dShipper }} / {{ priceRate.paid365dTrucker }})
        </p>
      </v-col>
      <v-col class="pt-0" cols="7">
        <div><h3>Posted</h3></div>
        <b class="float-left pr-1"> 7 Days: </b>
        <p class="mb-0">Reports: {{ priceRate.posted7dReports }}</p>
        <p v-if="priceRate.posted7dReports" class="mb-0">
          <tooltip
            :tooltip-content="
              joinString(
                priceRate.posted7dShipperRate,
                priceRate.posted7dTruckerRate,
                priceRate.posted7dShipper,
                priceRate.posted7dTrucker
              )
            "
          />
        </p>
        <b class="float-left pr-1"> 15 Days: </b>
        <p class="mb-0">Reports: {{ priceRate.posted15dReports }}</p>
        <p v-if="priceRate.posted15dReports" class="mb-0">
          <tooltip
            :tooltip-content="
              joinString(
                priceRate.posted15dShipperRate,
                priceRate.posted15dTruckerRate,
                priceRate.posted15dShipper,
                priceRate.posted15dTrucker
              )
            "
          />
        </p>
        <b class="float-left pr-1"> 30 Days: </b>
        <p class="mb-0">Reports: {{ priceRate.posted30dReports }}</p>
        <p v-if="priceRate.posted30dReports" class="mb-0">
          <tooltip
            :tooltip-content="
              joinString(
                priceRate.posted30dShipperRate,
                priceRate.posted30dTruckerRate,
                priceRate.posted30dShipper,
                priceRate.posted30dTrucker
              )
            "
          />
        </p>
        <b class="float-left pr-1"> 90 Days: </b>
        <p class="mb-0">Reports: {{ priceRate.posted90dReports }}</p>
        <p v-if="priceRate.posted90dReports" class="mb-0">
          <tooltip
            :tooltip-content="
              joinString(
                priceRate.posted90dShipperRate,
                priceRate.posted90dTruckerRate,
                priceRate.posted90dShipper,
                priceRate.posted90dTrucker
              )
            "
          />
        </p>
        <b class="float-left pr-1"> 365 Days: </b>
        <p class="mb-0">Reports: {{ priceRate.posted365dReports }}</p>
        <p v-if="priceRate.posted365dReports" class="mb-0">
          <tooltip
            :tooltip-content="
              joinString(
                priceRate.posted365dShipperRate,
                priceRate.posted365dTruckerRate,
                priceRate.posted365dShipper,
                priceRate.posted365dTrucker
              )
            "
          />
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import Tooltip from "@/views/pages/components/Tooltip"

export default {
  name: "TruckstopPrice",
  components: {
    Tooltip,
  },
  data() {
    return {
      margin: 0,
      radius: "",
      priceRate: {
        id: 0,
        load: 0,
        agentCompany: 0,
        margin: 0,
        radius: 0,
        paid30dReports: 0,
        paid90dReports: 0,
        paid365dReports: 0,
        posted7dReports: 0,
        posted15dReports: 0,
        posted30dReports: 0,
        posted90dReports: 0,
        posted365dReports: 0,
        paid30dTrucker: 0,
        paid30dShipper: 0,
        paid90dTrucker: 0,
        paid90dShipper: 0,
        paid365dTrucker: 0,
        paid365dShipper: 0,
        posted7dTrucker: 0,
        posted7dShipper: 0,
        posted15dTrucker: 0,
        posted15dShipper: 0,
        posted30dTrucker: 0,
        posted30dShipper: 0,
        posted90dTrucker: 0,
        posted90dShipper: 0,
        posted365dTrucker: 0,
        posted365dShipper: 0,
      },
      radiusData: [
        {
          text: "Within 100 Miles",
          value: "Within100Miles",
        },
        {
          text: "Within 150 Miles",
          value: "Within150Miles",
        },
        {
          text: "State To State",
          value: "StateToState",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
      truckStopPrices: "truckStopPrices",
      loadingIcon: "loadingIcon",
    }),
  },
  watch: {
    truckStopPrices() {
      this.priceRate = this.truckStopPrices
      this.margin = this.truckStopPrices.margin
      this.radius = this.truckStopPrices.radius
    },
  },
  mounted() {
    this.fetchDataOnLoad()
  },
  methods: {
    fetchTruckStopPrice() {
      const payload = {
        loadId: this.shipmentDetail.id,
        margin: this.margin,
        radius: this.radius,
      }
      this.$store.dispatch("loadDetail/FETCH_TRUCKSTOP_PRICE", payload)
    },
    fetchDataOnLoad() {
      this.$store.dispatch(
        "loadDetail/GET_TRUCKSTOP_PRICE",
        this.shipmentDetail.id
      )
    },
    joinString(shipperRate, truckerRate, shipper, trucker) {
      return (
        "$" +
        shipperRate +
        " / $" +
        truckerRate +
        " (" +
        shipper +
        " / " +
        trucker +
        ")"
      )
    },
  },
}
</script>

<style scoped>
#truckstopprice .container .v-input {
  padding-top: 0px;
}
</style>
