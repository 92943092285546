<template>
  <div v-if="!carrierInfoEdit" class="pt-3" data-cy="carrier-info">
    <v-row>
      <v-col
        :sm="$vuetify.breakpoint.mdAndUp ? '7' : '8'"
        class="pt-0 pb-0 pr-0"
        cols="12"
      >
        <v-list-item-subtitle
          class="
            blue--text
            text--lighten-2
            pa-1
            pl-3
            pb-4
            white--text
            box-header
          "
        >
          Carrier info
          <v-tooltip v-if="shipmentDetail.isPaid" right>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="text-truncate truncate"
                style="margin-left: 0px"
              >
                <v-icon medium> mdi-cash-check </v-icon>
              </span>
            </template>
            <div class="box">
              Carrier paid ${{
                shipmentDetail.carrierPaymentAmount.toFixed(2)
              }}
              via {{ shipmentDetail.carrierPaymentType }} on
              {{ shipmentDetail.paidDate }}
            </div>
          </v-tooltip>
          <v-btn
            v-if="!disableStatusButton"
            color="grey"
            small
            text
            @click="carrierInfoEditing()"
          >
            Edit
          </v-btn>
        </v-list-item-subtitle>
      </v-col>
    </v-row>
    <div
      :style="
        $vuetify.breakpoint.mdAndUp && showEdit
          ? 'height: 162px; overflow-y: auto;'
          : ''
      "
    >
      <v-container>
        <v-row>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Carrier</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <router-link
                v-if="carrierInfo"
                :to="{ name: 'Carrier Detail', params: { id: carrierInfo.id } }"
              >
                <tooltip
                  :tooltip-content="
                    carrierInfo ? carrierInfo.name : 'Not Assigned'
                  "
                />
              </router-link>
              <tooltip
                v-else
                :tooltip-content="
                  carrierInfo ? carrierInfo.name : 'Not Assigned'
                "
              />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">MC</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip
                :tooltip-content="carrierInfo ? carrierInfo.mcNumber : null"
              />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">DOT</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip
                :tooltip-content="carrierInfo ? carrierInfo.dotNumber : null"
              />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Phone</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip
                :tooltip-content="carrierInfo ? carrierInfo.phone : null"
              />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Email</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip
                :tooltip-content="carrierInfo ? carrierInfo.email : null"
              />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Dispatcher</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="shipmentDetail.dispatcher" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p
              v-if="$vuetify.breakpoint.lgAndUp"
              class="mb-1 grey--text text--darken-1"
            >
              Dispatch Phone
            </p>
            <p v-else class="mb-1 grey--text text--darken-1">Disp. Phone</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="shipmentDetail.dispatchPhone" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Dispatch Email</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="shipmentDetail.dispatchEmail" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Driver Phone</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="shipmentDetail.driverPhone" />
            </v-list-item-subtitle>
          </v-col>
          <v-col class="pt-0" cols="6" lg="4" md="6" sm="6">
            <p class="mb-1 grey--text text--darken-1">Driver Name</p>
            <v-list-item-subtitle class="grey--text text--darken-4">
              <tooltip :tooltip-content="shipmentDetail.driverName" />
            </v-list-item-subtitle>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
  <carrier-info-edit v-else />
</template>
<script>
import { mapGetters } from "vuex"
import CarrierInfoEdit from "@/views/pages/load-detail-components/edit-components/CarrierInfoEdit"
import Tooltip from "@/views/pages/components/Tooltip"
import common from "@/utils/common"

export default {
  name: "CarrierInfo",
  components: {
    Tooltip,
    CarrierInfoEdit,
  },
  props: {
    hgtClass: {
      type: String,
      default: "hgt150",
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("loadDetail", {
      carrierInfo: "carrierInfo",
      shipmentDetail: "shipmentDetail",
      carrierInfoEdit: "carrierInfoEdit",
    }),
    ...mapGetters("generic", {
      agentDetail: "agentDetail",
    }),
    disableStatusButton() {
      return common.methods.disableButtonBaseOnLoadStatus(
        this.shipmentDetail,
        this.agentDetail
      )
    },
  },
  methods: {
    carrierInfoEditing() {
      this.$store.commit("loadDetail/setCarrierInfoEditing", true)
    },
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}

.hgt150 {
  height: 150px;
  overflow-y: auto;
}

.v-application a {
  color: #000;
  text-decoration: none;
}
</style>
