<template>
  <div class="backend-customer-autocomplete">
    <v-autocomplete
      v-model="customer"
      clearable
      :items="customerCompaniesList"
      :loading="loading"
      :search-input.sync="search"
      color="info"
      item-text="name"
      item-value="id"
      :label="customerText"
      return-object
      close-on-content-click
      @keydown.enter="fireEnterEvent()"
      @change="pushCustomerCompany()"
      @click:clear="removeCustomer()"
    >
      <v-list-item slot="append-item">
        <v-btn text small @click="customerForm()">
          <v-icon>mdi-plus</v-icon> Add Customer
        </v-btn>
      </v-list-item>
    </v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce"
import api from "@/api/api"
import { mapGetters } from "vuex"

export default {
  name: "CustomerAutoComplete",
  props: {
    customerText: {
      type: String,
      default: "Customer",
    },
    defaultCustomer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectValue: null,
      loading: false,
      search: "",
      customerCompanies: [],
      isEnterEvent: false,
      selectedCustomer: null,
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
    }),
    ...mapGetters("generic", {
      customerId: "customerId",
    }),
    customerCompaniesList() {
      return this.customerCompanies.map((customerCompany) => {
        const name = customerCompany.text
        return Object.assign({}, customerCompany, { name })
      })
    },
    customer: {
      get() {
        return this.selectValue ? this.selectValue : null
      },
      set(newValue) {
        this.selectValue = newValue
        this.selectedCustomer = newValue
        if (this.isEnterEvent === true) {
          this.fireEnterEvent()
        }
      },
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0) {
        this.customerCompanies = []
        return
      }
      if (this.customer) {
        if (val !== this.customer.name) {
          if (this.loading) return
          this.loading = true
          this.getSearch()
        }
      } else {
        if (this.loading) return
        this.loading = true
        this.getSearch()
      }
    },
    customerId() {
      if (this.customerId === 0 || this.customerId === null) {
        this.customer = {
          id: null,
          name: null,
          text: null,
        }
      }
    },
  },
  mounted() {
    if (!this.defaultCustomer) {
      this.customer = {
        id: null,
        name: null,
        text: null,
      }
      this.customerCompanies = [
        {
          id: null,
          name: null,
          text: null,
        },
      ]
    } else {
      this.customer = {
        id: this.defaultCustomer.id,
        name: this.defaultCustomer.name,
        text: this.defaultCustomer.name,
      }
      this.customerCompanies = [
        {
          id: this.defaultCustomer.id,
          name: this.defaultCustomer.name,
          text: this.defaultCustomer.name,
        },
      ]
    }
  },
  methods: {
    getSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/customer-front-end-autocomplete/`,
          {
            params: {
              q: this.search,
            },
          }
        )
        .then((res) => {
          this.customerCompanies = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Failed to retrieve customer companies.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    }, 500),
    pushCustomerCompany() {
      if (this.selectValue) {
        this.$store.commit("generic/setCustomerId", this.customer.id)
        this.$emit("event", { customer: this.selectValue, flag: 2 })
      }
    },
    removeCustomer() {
      this.customer = null
      this.$store.commit("generic/setCustomerId", null)
    },
    customerForm() {
      this.$store.commit("create/setCustomerDialog", true)
    },
    fireEnterEvent() {
      this.isEnterEvent = true
      if (
        this.selectedCustomer &&
        this.selectedCustomer.id !== undefined &&
        this.selectedCustomer.id !== null &&
        this.isEnterEvent === true
      ) {
        this.$emit("event", {
          customer: this.selectedCustomer,
          flag: 1,
        })
        this.isEnterEvent = false
        this.selectedCustomer = null
      }
    },
  },
}
</script>
