<template>
  <v-container class="pl-0 pt-0 pr-0 pb-0">
    <v-row>
      <v-col
        :sm="$vuetify.breakpoint.lgAndUp ? '7' : '8'"
        class="pt-3 pb-0"
        cols="12"
      >
        <v-list-item-subtitle
          class="blue--text text--lighten-2 pa-1 pl-3 white--text box-header"
        >
          Carrier info
          <v-btn color="grey" small text @click="saveCarrierUpdate()">
            Save
          </v-btn>
        </v-list-item-subtitle>
      </v-col>
    </v-row>
    <div class="px-4 pt-3" style="height: 174px; overflow-y: auto">
      <v-form
        ref="carrierInfoEdit-form"
        v-model="carrierInfoFormValid"
        lazy-validation
      >
        <v-row>
          <v-col class="pr-0 pt-0 pb-0" cols="12" lg="10" md="12" sm="12">
            <v-container class="pt-0 pl-0 pb-0">
              <carrier-auto-complete :model="carrierInfo" @event="selectedCarrier" />
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" cols="12" lg="6" md="6" sm="12">
            <carrier-contact-select
              :data="dispatchContacts"
              :default-contact="defaultDispatcher"
              label="Dispatch contact"
              @event="setCarrierDispatchContact"
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="12" lg="6" md="6" sm="12">
            <v-text-field
              v-model="shipmentDetail.driverName"
              :rules="[rules.alphaNumeric]"
              class="pt-0"
              label="Driver Name"
              @keydown.enter.native="saveCarrierUpdate()"
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="12" lg="6" md="6" sm="12">
            <v-text-field
              v-model="shipmentDetail.driverPhone"
              :rules="[rules.phone]"
              class="pt-0"
              label="Driver Phone"
              @keydown.enter.native="saveCarrierUpdate()"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"
import CarrierAutoComplete from "@/views/pages/components/CarrierAutoComplete"
import CarrierContactSelect from "@/views/pages/load-detail-components/CarrierContactSelect"
import validation from "@/utils/form-validation"
export default {
  name: "CarrierInfoEdit",
  components: {
    CarrierAutoComplete,
    CarrierContactSelect,
  },
  data() {
    return {
      valid: false,
      carrierInfoFormValid: false,
      rules: validation.rules,
      dispatchContacts: [],
      carrier: {
        id: null,
        name: null,
        text: null,
        dispatchContact: null,
        driverContact: null,
      },
      defaultDispatcher: null,
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
      shipperInfo: "shipperInfo",
      consigneeInfo: "consigneeInfo",
      carrierInfo: "carrierInfo",
    }),
    ...mapGetters("carrier", ["carrierContacts"]),
  },
  mounted() {
    if (this.carrierInfo) {
      this.carrier = {
        id: this.carrierInfo.id,
        name: this.carrierInfo.name,
        text: this.carrierInfo.name,
        dispatchContact: this.carrierInfo.dispatcher,
        driverContact: this.carrierInfo.driver,
      }
      this.carriers = []
      this.getCarrierContacts()
    }
  },
  watch: {
    carrierInfo() {
      this.getCarrierContacts()
    },
    carrierContacts() {
      if (this.carrierContacts && this.carrierInfo) {
        const contacts = this.carrierContacts.results
        const dispatchContacts = contacts.filter((contact) => !contact.isDriver)
        this.dispatchContacts = dispatchContacts
        this.defaultDispatcher = dispatchContacts.find(
          (contact) => contact.id === this.shipmentDetail.dispatcher_id
        )
      }
    },
  },
  methods: {
    setCarrierDispatchContact(value) {
      this.shipmentDetail.dispatcher_id = value
    },
    getCarrierContacts() {
      const contactPayload = `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/contact/${this.carrierInfo.id}/?limit=100`
      this.$store.dispatch("carrier/GET_CARRIER_CONTACTS", contactPayload)
    },
    saveCarrierUpdate() {
      let payload = {}
      if (this.carrierInfo) {
        payload = {
          id: this.shipmentDetail.id,
          carrier: this.carrierInfo.id,
          dispatcher: this.shipmentDetail.dispatcher_id || null,
          driver_name: this.shipmentDetail.driverName || null,
          driver_phone: this.shipmentDetail.driverPhone || null,
        }
      } else {
        payload = {
          id: this.shipmentDetail.id,
          carrier: null,
          dispatcher: null,
          driver_name: null,
          driver_phone: null,
        }
      }
      this.$store
        .dispatch("loadDetail/PATCH_LOAD_DETAIL", payload)
        .then(() => {
          this.$store.commit("loadDetail/setCarrierInfoEditing", false)
        })
        .catch(() => {
          this.$store.commit("loadDetail/setCarrierInfoEditing", true)
        })
    },
      selectedCarrier(value) {
          if (value.carrier) {
              this.shipmentDetail.dispatcher_id = null
              this.getCarrierContacts()
          }
      }
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}
.hgt150 {
  height: 150px;
  overflow-y: auto;
}
</style>
