<template>
  <span v-if="shipmentDetail">
    <v-btn
      :class="loadStatusBtnClass"
      :disabled="disableClick"
      class="ma-0"
      small
      @click="openLoadTracking()"
    >
      {{
        shipmentDetail.archived
          ? "Archived"
          : shipmentDetail.hold
          ? "Hold"
          : loadStatusDisplay
      }}
    </v-btn>
  </span>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "LoadStatusButton",
  props: {
    shipmentDetail: {
      type: Object,
      default: null,
    },
    agentDetail: {
      type: Object,
      default: null,
    },
    loadStatusItems: {
      type: Array,
      default: null,
    },
    disableClick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttonColors: {
        plan: false,
        available: false,
        booked: false,
        confirmed: false,
        picked: false,
        delivered: false,
        claim: false,
        invoiced: false,
        paid: false,
        pickOnTrack: false,
        pickOffTrack: false,
        deliveryOnTrack: false,
        deliveryOffTrack: false,
        readyToBill: false,
        needsAppt: false,
        loading: false,
        unloading: false,
      },
      loadStatusDisplay: null,
    }
  },
  computed: {
    ...mapGetters("loadDetail", ["loadStatusChoices"]),
    loadStatusBtnClass() {
      if (this.shipmentDetail.loadStatus) {
        let status = null
        if (isNaN(this.shipmentDetail.loadStatus)) {
          status = this.loadStatusChoices.findIndex(
            (x) => x.name === this.shipmentDetail.loadStatus
          )
        } else {
          status = this.loadStatusChoices.findIndex(
            (x) => x.id === this.shipmentDetail.loadStatus
          )
        }
        this.determineButtonColor(this.loadStatusChoices[status].name)
        if (this.buttonColors.plan || this.buttonColors.needsAppt) {
          return "load-status-edit-button font-weight-bold yellow darken-2"
        } else if (this.buttonColors.available) {
          return "load-status-edit-button font-weight-bold green darken-2"
        } else if (this.buttonColors.booked) {
          return "load-status-edit-button font-weight-bold indigo lighten-1"
        } else if (this.buttonColors.confirmed) {
          return "load-status-edit-button font-weight-bold purple lighten-1"
        } else if (
          this.buttonColors.picked ||
          this.buttonColors.pickOnTrack ||
          this.buttonColors.loading
        ) {
          return "load-status-edit-button font-weight-bold blue lighten-1"
        } else if (
          this.buttonColors.delivered ||
          this.buttonColors.deliveryOnTrack ||
          this.buttonColors.unloading ||
          this.buttonColors.readyToBill
        ) {
          return "load-status-edit-button font-weight-bold green lighten-1"
        } else if (
          this.buttonColors.claim ||
          this.buttonColors.deliveryOffTrack ||
          this.buttonColors.pickOffTrack
        ) {
          return "load-status-edit-button font-weight-bold red darken-1"
        } else if (this.buttonColors.invoiced) {
          return "load-status-edit-button font-weight-bold blue-grey darken-2"
        } else {
          return "load-status-edit-button font-weight-bold black"
        }
      } else {
        return "load-status-edit-button font-weight-bold black"
      }
    },
  },
  methods: {
    determineButtonColor(statusName) {
      this.loadStatusDisplay = statusName
      for (const [key] of Object.entries(this.buttonColors)) {
        if (key.toLowerCase() !== statusName.toLowerCase().replace(" ", "")) {
          this.buttonColors[key] = false
        } else {
          this.buttonColors[key] = true
        }
      }
    },
    openLoadTracking() {
      if (this.disableClick === false) {
        if (this.shipmentDetail.id) {
          this.$store.commit(
            "loadDetail/setLoadTrackingDialogId",
            this.shipmentDetail.id
          )
          this.$store.dispatch(
            "loadDetail/GET_CURRENT_TRACKING_UPDATE",
            this.shipmentDetail.id
          )
        } else {
          this.$store.commit(
            "loadDetail/setLoadTrackingDialogId",
            this.shipmentDetail.loadNumber
          )
          this.$store.dispatch(
            "loadDetail/GET_CURRENT_TRACKING_UPDATE",
            this.shipmentDetail.loadNumber
          )
        }
        this.$store.commit("loadDetail/toggleLoadTrackingDialog", true)
      }
    },
  },
}
</script>
