<template>
  <div class="backend-location-autocomplete">
    <v-autocomplete
      v-model="location"
      :items="locationList"
      :loading="loading"
      :search-input.sync="search"
      color="info"
      clearable
      item-text="name"
      item-value="id"
      :label="`${label} Location`"
      return-object
      required
      close-on-content-click
      class="pt-0 pb-0"
      item-width="200"
      :filter="(v) => v"
      @change="pushLocation()"
      @click:clear="removeLocation()"
    >
      <template v-slot:prepend>
        <v-icon v-if="$vuetify.breakpoint.lgAndUp" :color="marker">
          mdi-map-marker
        </v-icon>
      </template>
      <template v-slot:item="data">
        <template v-if="data.item.disabled === true">
          <v-list-item-content v-text="data.item.name" />
        </template>
        <template v-else>
          <v-list-item-avatar>
            {{ shortLocationName(data.item.name) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name" />
            <v-list-item-subtitle v-html="data.item.address_preview" />
          </v-list-item-content>
        </template>
      </template>
      <v-list-item slot="append-item">
        <v-btn text small @click="locationForm()">
          <v-icon>mdi-plus</v-icon> Add Location
        </v-btn>
      </v-list-item>
    </v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce"
import api from "@/api/api"
import { mapGetters } from "vuex"
export default {
  name: "LocationAutoComplete",
  /*
    formTypes:
        1 = create location
        2 = update shipper location
        3 = update consignee location
        4 = create capacity

    stopSequence:
        1 = shipper
        2 = consignee
     */

  props: {
    direction: {
      type: String,
      default: null,
    },
    formType: {
      type: String,
      default: null,
    },
    stopSequence: {
      type: String,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      location: null,
      search: "",
      locations: [],
      suggestedLocations: [],
    }
  },
  computed: {
    ...mapGetters("location", {
      currentLocation: "currentLocation",
    }),
    ...mapGetters("loadDetail", {
      shipperInfo: "shipperInfo",
      consigneeInfo: "consigneeInfo",
    }),
    locationList() {
      const searchedLocations = []
      if (this.customerId) {
        if (this.locations.length > 0) {
          const customerLocations = this.locations.map((location) => {
            const name = location.name
            return Object.assign({}, location, { name })
          })
          customerLocations.unshift({
            name: "Customer Locations",
            disabled: true,
          })
          for (let i = 0; i < customerLocations.length; i++) {
            searchedLocations.push(customerLocations[i])
          }
        }
      }
      if (this.suggestedLocations.length > 0) {
        const locationData = this.suggestedLocations.map((location) => {
          const name = location.name
          return Object.assign({}, location, { name })
        })
        if (this.customerId) {
          locationData.unshift({
            name: "Suggested Locations",
            disabled: true,
          })
        }
        for (let i = 0; i < locationData.length; i++) {
          searchedLocations.push(locationData[i])
        }
      }
      return searchedLocations
    },
    marker() {
      if (this.direction === "source") {
        return "green"
      }
      return "red"
    },
    label() {
      return this.direction.charAt(0).toUpperCase() + this.direction.slice(1)
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0) {
        this.locations = []
        return
      }
      if (this.location) {
        if (val !== this.location.name) {
          if (this.loading) return
          this.loading = true
          this.getSearch()
        }
      } else {
        if (this.loading) return
        this.loading = true
        this.getSearch()
      }
    },
    location: function (val) {
      if (!val) {
        this.location = null
      }
    },
  },
  mounted() {
    const address = this.makeAddressPreview(this.model)
    if (this.shipperInfo && parseInt(this.formType) === 2) {
      this.location = {
        id: this.model.id,
        name: this.model.name,
        address_preview: address,
      }
      if (this.customerId) {
        this.locations = [
          {
            id: this.model.id,
            name: this.model.name,
            address_preview: address,
          },
        ]
      } else {
        this.suggestedLocations = [
          {
            id: this.model.id,
            name: this.model.name,
            address_preview: address,
          },
        ]
      }
    }

    if (this.consigneeInfo && parseInt(this.formType) === 3) {
      this.location = {
        id: this.model.id,
        name: this.model.name,
        address_preview: address,
      }
      if (this.customerId) {
        this.locations = [
          {
            id: this.model.id,
            name: this.model.name,
            address_preview: address,
          },
        ]
      } else {
        this.suggestedLocations = [
          {
            id: this.model.id,
            name: this.model.name,
            address_preview: address,
          },
        ]
      }
    }
  },
  methods: {
    getSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/locations/location-front-end-autocomplete/`,
          {
            params: {
              q: this.search,
            },
          }
        )
        .then((res) => {
          if (this.customerId) {
            this.getCustomerLocations()
          }
          this.suggestedLocations = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Could not retrieve locations.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    }, 1000),
    getCustomerLocations: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/locations/customer-location-autocomplete/`,
          {
            params: {
              q: this.search,
              customer_company_id: this.customerId,
            },
          }
        )
        .then((res) => {
          this.locations = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Could not retrieve locations.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    }, 1000),
    removeLocation() {
      if (parseInt(this.formType) === 2) {
        const shipperInfo = {
          id: null,
          name: null,
          address: null,
          city: null,
          state: null,
          zipcode: null,
          caProvince: null,
          caPostalCode: null,
          country: null,
          contactName: null,
          contactPhone: null,
          hours: null,
          notes: null,
          geoPoint: null,
          earlyTime: null,
          lateTime: null,
          latitude: null,
          longitude: null,
        }
        this.$store.commit("loadDetail/setShipperInfo", shipperInfo)
      }

      if (parseInt(this.formType) === 3) {
        const consigneeInfo = {
          id: null,
          name: null,
          address: null,
          city: null,
          state: null,
          zipcode: null,
          caProvince: null,
          caPostalCode: null,
          country: null,
          contactName: null,
          contactPhone: null,
          hours: null,
          notes: null,
          geoPoint: null,
          earlyTime: null,
          lateTime: null,
          latitude: null,
          longitude: null,
        }
        this.$store.commit("loadDetail/setConsigneeInfo", consigneeInfo)
      }
    },
    pushLocation() {
      if (this.location) {
        if (parseInt(this.formType) === 1) {
          if (this.direction === "source") {
            this.$store.commit("loadDetail/setShipperId", this.location.id)
          } else {
            this.$store.commit("loadDetail/setConsigneeId", this.location.id)
          }
        }

        if (parseInt(this.formType) === 2 || parseInt(this.formType) === 3) {
          this.$store.dispatch("location/GET_LOCATION", {
            locationId: this.location.id,
            formType: parseInt(this.formType),
          })
        }

        if (parseInt(this.formType) === 4) {
          const payload = {
            direction: this.direction,
            location: this.location,
          }
          this.$store.commit("create/setCapacityLocation", payload)
        }
      }
    },
    locationForm() {
      this.$store.commit("create/setLocationDialog", true)
    },
    shortLocationName(value) {
      let sortName = ""
      if (value !== undefined && value !== null) {
        sortName = value.match(/\b(\w)/g).join("")
        sortName = sortName.charAt(0) + sortName.charAt(1)
      }
      return sortName
    },
    makeAddressPreview(value) {
      return `${value?.address ?? ''} ${value.city}, ${value.stateProvinceRegion} ${
        value.postalCode === undefined || value.postalCode === "None"
          ? ""
          : value.postalCode
      }`.trim()
    },
  },
}
</script>
<style>
.v-autocomplete__content.v-menu__content {
  width: 330px !important;
}
</style>
