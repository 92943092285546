<template>
  <v-row no-gutters class="pl-3">
    <v-col cols="12">
      <v-form ref="tender-form" v-model="tenderFormValid" lazy-validation>
        <v-row>
          <v-col cols="12" class="py-4 px-0">
            <v-list-item-subtitle
              class="blue--text text--lighten-2 pa-1 pl-3 box-header mb-n4"
            >
              Tender Detail
              <v-btn text small color="grey" @click="saveLoadUpdate()">
                Save
              </v-btn>
            </v-list-item-subtitle>
          </v-col>
          <v-col cols="6">
            <v-responsive
              class="text-center blue lighten-2 rounded-circle d-inline-flex align-center justify-center"
              height="11"
              width="11"
            />
            <div
              :style="
                $vuetify.breakpoint.lgAndUp
                  ? 'border-left:1px solid #64B5F6; height:280px; margin-left:5px;'
                  : 'border-left:1px solid #64B5F6; height:280px; margin-left:5px; font-size: 10px'
              "
            >
              <v-text-field
                v-model="tenderDetails.customerBase"
                label="Customer Base"
                class="mt-0 ml-2"
                :rules="[rules.amount]"
                @keydown.enter.native="saveLoadUpdate()"
              />
              <div class="ml-2">
                <p class="mb-1 grey--text text--darken-1">Load Margin</p>
                <v-card-text
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  ${{ tenderDetails.loadMargin }}
                </v-card-text>
              </div>
              <div class="mt-4 ml-2">
                <p class="mb-1 grey--text text--darken-1">Percent Margin</p>
                <v-card-text
                  class="text--regular pa-0 grey--text text--darken-4"
                >
                  {{ tenderDetails.percentMargin }}%
                </v-card-text>
              </div>
              <div class="tender-detail-bottom-box pa-1">
                <p
                  v-if="$vuetify.breakpoint.lgAndUp"
                  class="mb-0 ml-1 grey--text text--darken-3 pa-1"
                >
                  Customer Total
                </p>
                <p v-else class="mb-0 ml-1 grey--text text--darken-3 pa-1">
                  Cus. Total
                </p>
                <v-card-text
                  class="text--regular pa-1 ml-1 blue--text text--lighten-1 font-weight-bold"
                >
                  ${{ tenderDetails.customerTotalPrice }}
                </v-card-text>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <v-responsive
              class="text-center blue lighten-2 rounded-circle d-inline-flex align-center justify-center"
              height="11"
              width="11"
            />
            <div
              :style="
                $vuetify.breakpoint.lgAndUp
                  ? 'border-left:1px solid #64B5F6; height:280px; margin-left:5px;'
                  : 'border-left:1px solid #64B5F6; height:280px; margin-left:5px; font-size: 10px'
              "
            >
              <v-row no-gutters>
                <v-col
                  :cols="$vuetify.breakpoint.lgAndUp ? '6' : '12'"
                  class="pt-0 pb-0"
                >
                  <v-text-field
                    v-model="tenderDetails.carrierStartBuy"
                    label="Carrier Start Buy"
                    class="mt-0 ml-2 pr-1"
                    :rules="[rules.amount]"
                    @keydown.enter.native="saveLoadUpdate()"
                  />
                </v-col>
                <v-col
                  :cols="$vuetify.breakpoint.lgAndUp ? '6' : '12'"
                  class="pt-0 pb-0"
                >
                  <v-text-field
                    v-model="tenderDetails.carrierMaxBuy"
                    label="Carrier Max Buy"
                    class="mt-0 ml-2 pr-1"
                    :rules="[rules.amount]"
                    @keydown.enter.native="saveLoadUpdate()"
                  />
                </v-col>
              </v-row>
              <v-text-field
                v-model="tenderDetails.carrierLineHaul"
                label="Carrier Line Haul"
                class="mt-0 ml-2 pr-1"
                :rules="[rules.amount]"
                @keydown.enter.native="saveLoadUpdate()"
              />
              <div class="tender-detail-bottom-box pa-1">
                <p class="mb-0 ml-1 grey--text text--darken-3 pa-1">
                  Carrier Total
                </p>
                <v-card-text
                  class="text--regular pa-1 ml-1 blue--text text--lighten-1 font-weight-bold"
                >
                  ${{ tenderDetails.carrierTotalPrice }}
                </v-card-text>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"

export default {
  name: "TenderDetail",
  data() {
    return {
      tenderFormValid: false,
      rules: validation.rules,
    }
  },
  computed: {
    ...mapGetters("loadDetail", {
      shipmentDetail: "shipmentDetail",
      shipperInfo: "shipperInfo",
      consigneeInfo: "consigneeInfo",
      tenderDetails: "tenderDetails",
    }),
  },
  methods: {
    saveLoadUpdate() {
      if (this.$refs["tender-form"].validate()) {
        const payload = {
          id: this.shipmentDetail.id,
          customer_price: this.checkBlankValue(this.tenderDetails.customerBase),
          carrier_start_buy: this.checkBlankValue(
            this.tenderDetails.carrierStartBuy
          ),
          carrier_max_buy: this.checkBlankValue(
            this.tenderDetails.carrierMaxBuy
          ),
          carrier_price: this.checkBlankValue(
            this.tenderDetails.carrierLineHaul
          ),
        }
        this.$store
          .dispatch("loadDetail/PATCH_LOAD_DETAIL", payload)
          .then(() => {
            this.$store.commit("loadDetail/setTenderDetailEditing", false)
          })
          .catch(() => {
            // Swallow to avoid Sentry throwing an error
          })
      }
    },
    checkBlankValue(val) {
      if (val === "" || val === null) {
        return "0"
      } else {
        return val
      }
    },
  },
}
</script>
<style scoped>
.tender-detail-input {
  max-height: 33px;
}
.rounded-circle {
  border-radius: 50% !important;
}
.tender-detail-bottom-box {
  background-color: rgba(30, 136, 229, 0.1);
  border: 1px solid #1e88e5;
  border-radius: 0px 4px 4px 4px;
  position: absolute;
  width: 40%;
  bottom: 30px;
  margin-left: -1px;
}
.box-header {
  font-size: 16px;
  font-weight: 500;
}
</style>
