<template>
  <div class="backend-carrier-autocomplete">
    <v-autocomplete
      v-model="carrier"
      :items="carrierList"
      :loading="loading"
      :search-input.sync="search"
      color="info"
      clearable
      item-text="name"
      item-value="id"
      label="Carrier"
      return-object
      required
      close-on-content-click
      @keydown.enter="fireEnterEvent()"
      @blur="pushCarrier()"
      @click:clear="removeCarrier()"
    />
  </div>
</template>

<script>
import debounce from "lodash/debounce"
import api from "@/api/api"
import { mapGetters } from "vuex"
import common from "@/utils/common"

const snackbarType = common.data.snackbarType

export default {
  name: "CarrierAutoComplete",
  props: {
    model: {
      type: Object,
      default: null,
    },
    carrierText: {
      type: String,
      default: "Carrier",
    },
  },
  data() {
    return {
      selectValue: null,
      loading: false,
      search: "",
      carriers: [],
      isEnterEvent: false,
      selectedCarrier: null,
    }
  },
  computed: {
    ...mapGetters("carrier", {
      carrierId: "carrierId",
    }),
    carrierList() {
      return this.carriers.map((carrier) => {
        const name = carrier.text
        return Object.assign({}, carrier, { name })
      })
    },
    carrier: {
      get() {
        return this.selectValue ? this.selectValue : null
      },
      set(newValue) {
        this.selectValue = newValue
        this.selectedCarrier = newValue
        if (this.isEnterEvent === true) {
          this.fireEnterEvent()
        }
      },
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0) {
        this.carriers = []
        return
      }
      if (this.carrier) {
        if (val !== this.carrier.name) {
          if (this.loading) return
          this.loading = true
          this.getSearch()
        }
      } else {
        if (this.loading) return
        this.loading = true
        this.getSearch()
      }
    },
    carrierId() {
      if (this.carrierId === 0 || this.carrierId === null) {
        this.carrier = {
          id: null,
          name: null,
          text: null,
        }
      }
    },
  },
  mounted() {
    if (this.model != null) {
      this.carrier = {
        id: this.model.id,
        name: this.model.name,
        text: this.model.name,
      }
      this.carriers = [
        {
          id: this.model.id,
          name: this.model.name,
          text: this.model.name,
        },
      ]
    }
  },
  methods: {
    getSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/carrier-front-end-autocomplete/`,
          {
            params: {
              q: this.search,
            },
          }
        )
        .then((res) => {
          this.carriers = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Whoops, something went wrong",
            error: err,
            type: snackbarType.error,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
        })
        .finally((this.loading = false))
    }, 500),
    pushCarrier() {
      if (this.selectValue) {
        this.$store.commit("loadDetail/setCarrierInfo", this.carrier)
        this.$store.commit("carrier/setCarrierId", this.carrier.id)
        this.$emit("event", { carrier: this.selectValue, flag: 2 })
      }
    },
    removeCarrier() {
      this.$store.commit("loadDetail/setCarrierInfo", null)
      this.$store.commit("carrier/setCarrierId", null)
    },
    fireEnterEvent() {
      this.isEnterEvent = true
      if (
        this.selectedCarrier &&
        this.selectedCarrier.id !== undefined &&
        this.selectedCarrier.id !== null &&
        this.isEnterEvent === true
      ) {
        this.$emit("event", { carrier: this.selectedCarrier, flag: 1 })
        this.isEnterEvent = false
        this.selectedCarrier = null
      }
    },
  },
}
</script>
