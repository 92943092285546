<template>
  <div class="backend-customer-autocomplete">
    <v-autocomplete
      v-model="manager"
      :items="managerList"
      :loading="loading"
      :search-input.sync="search"
      color="info"
      item-text="text"
      item-value="id"
      :label="managerText"
      return-object
      required
      close-on-content-click
      @keydown.enter="fireEnterEvent()"
      @blur="pushAccountManager()"
      @click:clear="removeAccountManager()"
    />
  </div>
</template>

<script>
import debounce from "lodash/debounce"
import api from "@/api/api"
import { mapGetters } from "vuex"
export default {
  name: "AccountManagerAutoComplete",
  props: {
    managerText: {
      type: String,
      default: "Account Manager",
    },
    color: {
      type: String,
      default: "info",
    },
    defaultManager: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectValue: null,
      loading: false,
      search: "",
      accountManagerList: [],
      isEnterEvent: false,
      selectedManager: null,
    }
  },
  computed: {
    ...mapGetters("generic", {
      accountManager: "accountManager",
    }),
    managerList() {
      return this.accountManagerList.map((am) => {
        const name = am.text
        return Object.assign({}, am, { name })
      })
    },
    manager: {
      get() {
        return this.selectValue ? this.selectValue : null
      },
      set(newValue) {
        this.selectValue = newValue
        this.selectedManager = newValue
        if (this.isEnterEvent === true) {
          this.fireEnterEvent()
        }
      },
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0) {
        this.accountManagerList = []
        return
      }
      if (this.manager) {
        if (val !== this.manager.name) {
          if (this.loading) return
          this.loading = true
          this.getSearch()
        }
      } else {
        if (this.loading) return
        this.loading = true
        this.getSearch()
      }
    },
    accountManager() {
      if (this.accountManager === null) {
        this.manager = {
          id: null,
          name: null,
          text: null,
        }
        this.accountManagerList = []
      }
    },
    defaultManager() {
      if (this.defaultManager) {
        this.manager = {
          id: this.defaultManager.id,
          name: this.defaultManager.name,
          text: this.defaultManager.text,
        }
        this.accountManagerList = [
          {
            id: this.defaultManager.id,
            name: this.defaultManager.name,
            text: this.defaultManager.text,
          },
        ]
      } else {
        this.accountManagerList = []
      }
    },
  },
  mounted() {
    if (this.defaultManager) {
      this.manager = {
        id: this.defaultManager.id,
        name: this.defaultManager.name,
        text: this.defaultManager.text,
      }
      this.accountManagerList = [
        {
          id: this.defaultManager.id,
          name: this.defaultManager.name,
          text: this.defaultManager.text,
        },
      ]
    } else {
      this.accountManagerList = []
    }
  },
  methods: {
    getSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/account-manager-autocomplete/`,
          {
            params: {
              q: this.search,
            },
          }
        )
        .then((res) => {
          this.accountManagerList = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Failed to get account managers.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    }, 500),
    pushAccountManager() {
      if (this.selectValue) {
        this.$store.commit("generic/setAccountManager", this.selectValue)
        this.$emit("event", { manager: this.selectValue, flag: 2 })
      }
    },
    removeAccountManager() {
      this.$store.commit("generic/setAccountManager", null)
    },
    fireEnterEvent() {
      this.isEnterEvent = true
      if (
        this.selectedManager &&
        this.selectedManager.id !== undefined &&
        this.isEnterEvent === true
      ) {
        this.$emit("event", { manager: this.selectedManager, flag: 1 })
        this.isEnterEvent = false
        this.selectedManager = null
      }
    },
  },
}
</script>
